import React, { useState } from 'react';
import { 
  Target,
  Fingerprint, 
  BarChart2, 
  Award,
  Users,
  Shield,
  CheckCircle
} from 'lucide-react';
const RegisterForm = ({ onRegister, onSwitch, error }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const bgStyle = {
    backgroundImage: "url('https://static.vecteezy.com/system/resources/previews/019/588/966/non_2x/liquid-metal-background-wave-of-molten-metal-texture-marble-texture-formed-by-mixing-the-black-and-white-paint-black-wavy-shapes-background-black-and-white-abstractions-with-different-geometric-vector.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed'
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Las contraseñas no coinciden");
      return;
    }
    if (!email || !firstName || !lastName) {
      alert("Por favor, completa todos los campos");
      return;
    }
    onRegister(email, password, firstName, lastName);
  };

 return (
  <div className="min-h-screen bg-gray-900" style={bgStyle}>
    <div className="min-h-screen backdrop-blur-sm bg-black/30">
      {/* Hero Section */}
      <div className="min-h-screen flex flex-col md:flex-row items-center justify-center p-4 md:p-8">
        {/* Left Side - Message */}
        <div className="md:w-1/2 max-w-xl px-4 mb-8 md:mb-0 text-center md:text-left backdrop-blur-md bg-black/20 p-8 rounded-lg">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Comienza tu viaje hacia una <span className="text-green-400">marca personal única</span>
          </h1>
          <p className="text-gray-300 text-lg mb-8">
            Únete a la comunidad de profesionales que están transformando su presencia digital.
          </p>
          <div className="space-y-4">
            <div className="flex items-center gap-3 text-gray-300">
              <div className="w-10 h-10 rounded-full bg-green-600/20 flex items-center justify-center">
                <Target className="text-green-400" size={20} />
              </div>
              <span>91% más de oportunidades profesionales</span>
            </div>
            <div className="flex items-center gap-3 text-gray-300">
              <div className="w-10 h-10 rounded-full bg-green-600/20 flex items-center justify-center">
                <Award className="text-green-400" size={20} />
              </div>
              <span>82% mayor credibilidad en el sector</span>
            </div>
          </div>
        </div>

        {/* Right Side - Form */}
        <div className="w-full md:w-1/2 max-w-md px-4">
          <div className="backdrop-blur-md bg-black/50 p-8 rounded-lg shadow-2xl border border-white/10">
            <h2 className="text-3xl font-bold mb-2 text-white">Crear cuenta</h2>
            <p className="text-gray-300 text-sm mb-6">
              Completa tus datos para comenzar a construir tu marca personal
            </p>
            {error && <p className="text-red-400 mb-4">{error}</p>}
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-300">Nombre:</label>
                  <input
                    type="text"
                    id="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm text-white placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500"
                    placeholder="Juan"
                  />
                </div>
                <div>
                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-300">Apellido:</label>
                  <input
                    type="text"
                    id="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm text-white placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500"
                    placeholder="Pérez"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-300">Correo electrónico:</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm text-white placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500"
                  placeholder="tucorreo@ejemplo.com"
                />
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-300">Contraseña:</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm text-white placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500"
                  placeholder="••••••••"
                />
              </div>
              <div>
                <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-300">Confirmar Contraseña:</label>
                <input
                  type="password"
                  id="confirm-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm text-white placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500"
                  placeholder="••••••••"
                />
              </div>
              <button type="submit" className="w-full py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-green-500 transition-colors">
                Crear cuenta
              </button>
            </form>
            <p className="mt-6 text-center text-sm text-gray-300">
              ¿Ya tienes una cuenta?{' '}
              <button onClick={onSwitch} className="font-medium text-green-400 hover:text-green-300 transition-colors">
                Inicia Sesión
              </button>
            </p>
          </div>
        </div>
      </div>

      {/* Process Steps */}
      <section className="py-16 px-4 backdrop-blur-sm bg-black/30">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-2xl font-bold text-white text-center mb-12">
            Comienza tu camino al éxito profesional
          </h2>
          <div className="grid md:grid-cols-4 gap-8">
            {[
              {
                icon: <Target size={24} />,
                step: "1",
                title: "Regístrate",
                description: "Crea tu cuenta y accede a todas las herramientas"
              },
              {
                icon: <Fingerprint size={24} />,
                step: "2",
                title: "Define tu marca",
                description: "Establece tu identidad profesional única"
              },
              {
                icon: <Users size={24} />,
                step: "3",
                title: "Conéctate",
                description: "Construye tu red profesional estratégica"
              },
              {
                icon: <BarChart2 size={24} />,
                step: "4",
                title: "Crece",
                description: "Potencia tu presencia y alcanza tus metas"
              }
            ].map((step, index) => (
              <div key={index} className="relative">
                <div className="bg-black/50 backdrop-blur-md p-6 rounded-lg border border-white/10 text-center">
                  <div className="w-12 h-12 bg-green-600/20 rounded-full flex items-center justify-center mx-auto mb-4 text-green-400">
                    {step.icon}
                  </div>
                  <div className="absolute -top-3 -right-3 w-8 h-8 bg-green-500 rounded-full flex items-center justify-center text-white font-bold">
                    {step.step}
                  </div>
                  <h3 className="text-xl font-semibold text-white mb-2">{step.title}</h3>
                  <p className="text-gray-300 text-sm">{step.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-16 px-4 backdrop-blur-sm bg-black/20">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-2xl font-bold text-white text-center mb-12">
            Beneficios de unirse a Personal Marka
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: <Shield size={24} />,
                title: "Presencia Profesional",
                description: "Desarrolla una imagen profesional sólida y coherente en todas las plataformas digitales"
              },
              {
                icon: <CheckCircle size={24} />,
                title: "Gestión Simplificada",
                description: "Herramientas intuitivas para gestionar tu presencia digital de manera efectiva"
              },
              {
                icon: <Award size={24} />,
                title: "Crecimiento Continuo",
                description: "Acceso a recursos y estrategias para el desarrollo profesional continuo"
              }
            ].map((benefit, index) => (
              <div key={index} className="bg-black/50 backdrop-blur-md p-6 rounded-lg border border-white/10 hover:border-green-500 transition-colors">
                <div className="w-12 h-12 bg-green-600/20 rounded-lg flex items-center justify-center mb-4 text-green-400">
                  {benefit.icon}
                </div>
                <h3 className="text-xl font-semibold text-white mb-2">{benefit.title}</h3>
                <p className="text-gray-300">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Final CTA */}
      <section className="py-16 px-4 backdrop-blur-sm bg-black/30">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-white mb-4">
            ¿Listo para empezar?
          </h2>
          <p className="text-gray-300 mb-8">
            Únete a miles de profesionales que ya están construyendo su marca personal con nosotros
          </p>
          <button 
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="px-8 py-3 bg-green-600 hover:bg-green-700 text-white rounded-lg font-medium transition-colors"
          >
            Crear mi cuenta ahora
          </button>
        </div>
      </section>
    </div>
  </div>
);
};

export default RegisterForm;