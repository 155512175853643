import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Search, ArrowUpDown, UserCog, Ban, 
  Trash2, Activity, Star, FileSpreadsheet, 
  Users, Calendar, RefreshCw, Filter,
  Gift, X, Loader2, ClipboardCopy,  List
} from 'lucide-react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';

// Función mejorada para procesar datos Excel
const processDataForExcel = (users) => {
  return users.map(user => {
    let responsesText = 'No se registraron respuestas';
    if (user.responses && Object.keys(user.responses).length > 0) {
      try {
        const responsesObj = typeof user.responses === 'string' ? 
          JSON.parse(user.responses) : user.responses;
        if (Object.keys(responsesObj).length > 0) {
          responsesText = JSON.stringify(responsesObj, null, 2);
        }
      } catch (e) {
        responsesText = 'Error al procesar respuestas';
      }
    }

    let marketingPlansText = 'No hay planes registrados';
    if (user.marketing_plans && user.marketing_plans.length > 0) {
      try {
        const plansArray = typeof user.marketing_plans === 'string' ? 
          JSON.parse(user.marketing_plans) : user.marketing_plans;
        if (plansArray.length > 0) {
          marketingPlansText = JSON.stringify(plansArray, null, 2);
        }
      } catch (e) {
        marketingPlansText = 'Error al procesar planes';
      }
    }

    return {
      'ID': user.id,
      'Email': user.email,
      'Nombre': user.name || 'No registrado',
      'Estado': user.status || 'Sin estado',
      'Plan Actual': user.subscription?.plan || 'Sin plan',
      'Fecha Fin Suscripción': user.subscription?.end_date ? 
        new Date(user.subscription.end_date).toLocaleString() : 'N/A',
      'Última Actividad': user.last_activity ? 
        new Date(user.last_activity).toLocaleString() : 'Nunca',
      'Respuestas': responsesText,
      'Planes de Marketing': marketingPlansText,
      'Fecha Registro': user.registration_date ? 
        new Date(user.registration_date).toLocaleString() : 'No disponible',
      'Cupones Asignados': user.coupons?.join(', ') || 'Ninguno'
    };
  });
};

const exportToExcel = (users) => {
  const processedData = processDataForExcel(users);
  const ws = XLSX.utils.json_to_sheet(processedData);
  
  ws['!cols'] = [
    { wch: 5 },   // ID
    { wch: 30 },  // Email
    { wch: 25 },  // Nombre
    { wch: 15 },  // Estado
    { wch: 15 },  // Plan Actual
    { wch: 20 },  // Fecha Fin Suscripción
    { wch: 20 },  // Última Actividad
    { wch: 50 },  // Respuestas
    { wch: 50 },  // Planes de Marketing
    { wch: 20 }   // Fecha Registro
  ];

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Usuarios");
  
  const date = new Date().toISOString().split('T')[0];
  XLSX.writeFile(wb, `usuarios_personalmarka_${date}.xlsx`);
};

const UserStats = () => {
  const [userStats, setUserStats] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showCouponListModal, setShowCouponListModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'ascending' });
  const [selectedUser, setSelectedUser] = useState(null);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [statusFilter, setStatusFilter] = useState('all');
  const [planFilter, setPlanFilter] = useState('all');

  useEffect(() => {
    fetchUserStats();
    const interval = setInterval(fetchUserStats, 60000);
    return () => clearInterval(interval);
  }, []);

  const fetchUserStats = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/users`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      setUserStats(response.data);
      setError(null);
    } catch (err) {
      setError(err.response?.status === 403 
        ? 'Acceso denegado. Se requieren privilegios de administrador.'
        : 'Error al obtener las estadísticas de usuarios'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === 'ascending' ? 'descending' : 'ascending',
    }));
  };

  const applyFilters = (users) => {
    return users.filter(user => {
      const matchesStatus = statusFilter === 'all' || user.status === statusFilter;
      const matchesPlan = planFilter === 'all' || 
        (planFilter === 'none' ? !user.subscription?.plan : user.subscription?.plan === planFilter);
      const matchesSearch = 
        user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(user.id).includes(searchTerm);

      return matchesStatus && matchesPlan && matchesSearch;
    });
  };

  const handleUpdatePlan = async (userId, plan, skipPayment = false) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/admin/users/${userId}/subscription`,
        { 
          plan,
          status: 'active',
          end_date: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000).toISOString(),
          skipPayment
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      fetchUserStats();
      setShowPlanModal(false);
      toast.success('Plan actualizado exitosamente');
    } catch (err) {
      toast.error('Error al actualizar el plan');
    }
  };

  const handleUpdateStatus = async (userId, newStatus) => {
    if (!window.confirm(`¿Estás seguro de que quieres ${newStatus === 'suspended' ? 'suspender' : 'cambiar el estado de'} este usuario?`)) {
      return;
    }
    
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/admin/users/${userId}/status`,
        { status: newStatus },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      fetchUserStats();
      toast.success('Estado actualizado exitosamente');
    } catch (err) {
      toast.error('Error al actualizar el estado del usuario');
    }
  };

  const handleDeleteUser = async (userId) => {
    if (!window.confirm('¿Estás seguro de que deseas eliminar este usuario? Esta acción no se puede deshacer.')) {
      return;
    }
    
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/admin/users/${userId}`,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      fetchUserStats();
      toast.success('Usuario eliminado exitosamente');
    } catch (err) {
      toast.error('Error al eliminar el usuario');
    }
  };

  const sortUsers = (users) => {
    return [...users].sort((a, b) => {
      let aValue = sortConfig.key === 'id' ? a[sortConfig.key] : 
                   sortConfig.key === 'plan_actual' ? a.subscription?.plan || '' :
                   sortConfig.key === 'última_actividad' ? new Date(a.last_activity || 0) :
                   a[sortConfig.key] || '';
      
      let bValue = sortConfig.key === 'id' ? b[sortConfig.key] :
                   sortConfig.key === 'plan_actual' ? b.subscription?.plan || '' :
                   sortConfig.key === 'última_actividad' ? new Date(b.last_activity || 0) :
                   b[sortConfig.key] || '';

      if (sortConfig.direction === 'ascending') {
        return aValue > bValue ? 1 : -1;
      }
      return aValue < bValue ? 1 : -1;
    });
  };

  // Componentes UI
  const StatusBadge = ({ status }) => {
    const getStatusStyles = () => {
      switch (status) {
        case 'active':
          return 'bg-green-100 text-green-800';
        case 'suspended':
          return 'bg-yellow-100 text-yellow-800';
        case 'deleted':
          return 'bg-red-100 text-red-800';
        default:
          return 'bg-gray-100 text-gray-800';
      }
    };

    return (
      <span className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusStyles()}`}>
        {status === 'active' ? 'Activo' : 
         status === 'suspended' ? 'Suspendido' : 
         status === 'deleted' ? 'Eliminado' : 'Sin estado'}
      </span>
    );
  };

  const StatCard = ({ icon: Icon, title, value, className }) => (
    <div className={`p-4 rounded-lg ${className}`}>
      <div className="flex items-center space-x-3">
        <Icon className="text-white" size={24} />
        <div>
          <h3 className="text-sm text-white/80">{title}</h3>
          <p className="text-2xl font-bold text-white">{value}</p>
        </div>
      </div>
    </div>
  );

  const CouponModal = ({ onClose }) => {
    const [couponCode, setCouponCode] = useState('');
    const [selectedDiscount, setSelectedDiscount] = useState(25);
    const [loading, setLoading] = useState(false);
  
    const generateCoupon = () => {
      const prefix = selectedDiscount === 100 ? 'PREMIUM' : 
                    selectedDiscount === 50 ? 'SUPER' : 'BASIC';
      const randomPart = Math.random().toString(36).substring(2, 8).toUpperCase();
      const timestamp = Date.now().toString(36).substring(-4).toUpperCase();
      return `${prefix}-${randomPart}-${timestamp}`;
    };
  
    const handleCreateCoupon = async () => {
      setLoading(true);
      try {
        const newCouponCode = generateCoupon();
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/admin/coupons`,
          {
            code: newCouponCode,
            discount: selectedDiscount,
          },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }
        );
  
        setCouponCode(newCouponCode);
        toast.success('Cupón creado exitosamente');
      } catch (error) {
        toast.error('Error al crear el cupón');
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
      >
        <motion.div 
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          className="bg-gray-800 rounded-xl shadow-2xl p-6 w-full max-w-md"
        >
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-bold text-white">Generar Nuevo Cupón</h2>
            <button 
              onClick={onClose}
              className="text-gray-400 hover:text-white transition-colors"
            >
              <X size={20} />
            </button>
            <button
            onClick={() => setShowCouponListModal(true)}
            className="bg-indigo-600 hover:bg-indigo-700 rounded-lg transition-colors duration-200 flex items-center justify-center space-x-3"
          >
            <List className="text-white" size={24} />
            <span className="text-white font-medium">Ver Cupones</span>
          </button>
          </div>
  
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Seleccionar Descuento
              </label>
              <select
                value={selectedDiscount}
                onChange={(e) => setSelectedDiscount(Number(e.target.value))}
                className="w-full bg-gray-700 text-white rounded-lg p-2 border border-gray-600"
              >
                <option value={25}>25% de descuento</option>
                <option value={50}>50% de descuento</option>
                <option value={100}>100% de descuento (Plan Premium)</option>
              </select>
            </div>
  
            {couponCode && (
              <div className="p-4 bg-gray-700 rounded-lg">
                <p className="text-sm text-gray-300 mb-2">Cupón generado:</p>
                <div className="flex items-center justify-between">
                  <p className="text-lg font-mono text-white break-all">{couponCode}</p>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(couponCode);
                      toast.success('Código copiado');
                    }}
                    className="ml-2 p-1 hover:bg-gray-600 rounded transition-colors"
                  >
                    <ClipboardCopy size={18} className="text-gray-400" />
                  </button>
                </div>
                <p className="text-sm text-gray-400 mt-2">
                  Válido por 30 días
                </p>
              </div>
            )}
  
            <button
              onClick={handleCreateCoupon}
              disabled={loading}
              className="w-full py-2 px-4 bg-purple-600 hover:bg-purple-700 text-white rounded-lg transition-colors"
            >
              {loading ? (
                <span className="flex items-center justify-center">
                  <Loader2 className="animate-spin mr-2" size={18} />
                  Generando...
                </span>
              ) : (
                'Generar Cupón'
              )}
            </button>
          </div>
        </motion.div>
      </motion.div>
    );
  };
  const CouponListModal = ({ onClose }) => {
    const [coupons, setCoupons] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      fetchCoupons();
    }, []);
  
    const fetchCoupons = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/coupons`,
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }
        );
        setCoupons(response.data.coupons);
      } catch (error) {
        toast.error('Error al cargar los cupones');
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
      >
        <motion.div 
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          className="bg-gray-800 rounded-xl shadow-2xl p-6 w-full max-w-4xl max-h-[80vh] overflow-y-auto"
        >
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-bold text-white">Cupones Activos</h2>
            <button 
              onClick={onClose}
              className="text-gray-400 hover:text-white transition-colors"
            >
              <X size={20} />
            </button>
          </div>
  
          {loading ? (
            <div className="flex justify-center items-center py-8">
              <Loader2 className="animate-spin text-blue-500" size={32} />
            </div>
          ) : (
            <div className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {coupons.filter(coupon => !coupon.used).map((coupon) => (
                  <div
                    key={coupon.id}
                    className="bg-gray-700/50 rounded-lg p-4 relative overflow-hidden"
                  >
                    <div className="absolute top-2 right-2">
                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(coupon.code);
                          toast.success('Código copiado');
                        }}
                        className="p-1 hover:bg-gray-600 rounded transition-colors"
                      >
                        <ClipboardCopy size={16} className="text-gray-400" />
                      </button>
                    </div>
                    <div className="space-y-2">
                      <p className="font-mono text-lg text-white">{coupon.code}</p>
                      <div className="flex items-center space-x-2">
                        <Star className="text-yellow-400" size={16} />
                        <p className="text-gray-300">{coupon.discount}% descuento</p>
                      </div>
                      <p className="text-sm text-gray-400">
                        Expira: {new Date(coupon.expires_at).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
  
              {coupons.filter(coupon => !coupon.used).length === 0 && (
                <div className="text-center py-8 text-gray-400">
                  No hay cupones activos disponibles
                </div>
              )}
            </div>
          )}
        </motion.div>
      </motion.div>
    );
  };
  const PlanModal = ({ userId, onClose }) => (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
    >
      <motion.div 
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-gray-800 rounded-xl shadow-2xl p-6 w-full max-w-md"
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold text-white">Seleccionar Plan</h2>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <X size={20} />
          </button>
        </div>
        
        <div className="space-y-4">
          {['foco', 'potencia', 'brillante'].map((plan) => (
            <button
              key={plan}
              onClick={() => handleUpdatePlan(userId, plan)}
              className="w-full p-4 bg-gray-700 hover:bg-gray-600 rounded-lg flex items-center justify-between text-white transition-colors duration-200"
            >
              <span className="capitalize font-medium">{plan}</span>
              <Star size={20} className="text-yellow-400" />
            </button>
          ))}
        </div>
      </motion.div>
    </motion.div>
  );

  const UserDetailsModal = ({ user, onClose }) => (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
    >
      <motion.div 
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-gray-800 rounded-xl shadow-2xl p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto"
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-white">Detalles del Usuario</h2>
          <button 
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <X size={20} />
          </button>
        </div>

        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-gray-700/50 p-6 rounded-lg">
              <h3 className="text-lg font-semibold text-white mb-4">Información Personal</h3>
              <div className="space-y-3">
                <p className="text-gray-300">
                  <span className="text-gray-400">ID:</span> {user.id}
                </p>
                <p className="text-gray-300">
                  <span className="text-gray-400">Email:</span> {user.email}
                </p>
                <p className="text-gray-300">
                  <span className="text-gray-400">Nombre:</span> {user.name || 'No registrado'}
                </p>
                <p className="text-gray-300">
                  <span className="text-gray-400">Estado:</span> <StatusBadge status={user.status} />
                </p>
              </div>
            </div>

            <div className="bg-gray-700/50 p-6 rounded-lg">
              <h3 className="text-lg font-semibold text-white mb-4">Suscripción</h3>
              <div className="space-y-3">
                <p className="text-gray-300">
                  <span className="text-gray-400">Plan actual:</span>
                  <span className="capitalize ml-2">{user.subscription?.plan || 'Sin plan'}</span>
                </p>
                <p className="text-gray-300">
                  <span className="text-gray-400">Fecha fin:</span>
                  <span className="ml-2">
                    {user.subscription?.end_date ? 
                      new Date(user.subscription.end_date).toLocaleDateString() : 
                      'N/A'}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="bg-gray-700/50 p-6 rounded-lg">
            <h3 className="text-lg font-semibold text-white mb-4">Cupones</h3>
            {user.coupons && user.coupons.length > 0 ? (
              <div className="space-y-2">
                {user.coupons.map((coupon, index) => (
                  <div key={index} className="bg-gray-800/50 p-3 rounded-lg flex justify-between items-center">
                    <p className="text-white font-mono">{coupon}</p>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-400 italic">No hay cupones asignados</p>
            )}
          </div>

          <div className="bg-gray-700/50 p-6 rounded-lg">
            <h3 className="text-lg font-semibold text-white mb-4">Respuestas</h3>
            {user.responses && Object.keys(user.responses).length > 0 ? (
              <pre className="text-gray-300 text-sm overflow-x-auto whitespace-pre-wrap bg-gray-800/50 p-4 rounded-lg">
                {JSON.stringify(user.responses, null, 2)}
              </pre>
            ) : (
              <p className="text-gray-400 italic">No se registraron respuestas</p>
            )}
          </div>

          <div className="bg-gray-700/50 p-6 rounded-lg">
            <h3 className="text-lg font-semibold text-white mb-4">Planes de Marketing</h3>
            {user.marketing_plans && user.marketing_plans.length > 0 ? (
              <div className="space-y-4">
                {user.marketing_plans.map((plan, index) => (
                  <div key={index} className="bg-gray-800/50 p-4 rounded-lg">
                    <h4 className="font-medium text-white mb-2">Plan #{index + 1}</h4>
                    <pre className="text-gray-300 text-sm overflow-x-auto whitespace-pre-wrap">
                      {JSON.stringify(plan, null, 2)}
                    </pre>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-400 italic">No hay planes registrados</p>
            )}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );

  const filteredUsers = userStats?.users ? 
    sortUsers(applyFilters(userStats.users)) : [];

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 flex items-center justify-center">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-blue-600 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-gray-400">Cargando usuarios...</p>
        </div>
      </div>
    );
  }

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 p-6"
    >
      <div className="w-full mx-auto">
        {/* Header y Stats */}
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-5 mb-6">
          <StatCard
            icon={Users}
            title="Total Usuarios"
            value={userStats?.total_users || 0}
            className="bg-blue-600"
          />
          <StatCard
            icon={Activity}
            title="Usuarios Activos"
            value={userStats?.active_now || 0}
            className="bg-green-600"
          />
          <StatCard
            icon={Calendar}
            title="Usuarios Nuevos (30d)"
            value={userStats?.new_users_30d || 0}
            className="bg-purple-600"
          />
          <button
            onClick={() => userStats?.users && exportToExcel(userStats.users)}
            className="bg-emerald-600 hover:bg-emerald-700 rounded-lg transition-colors duration-200 flex items-center justify-center space-x-3"
          >
            <FileSpreadsheet className="text-white" size={24} />
            <span className="text-white font-medium">Exportar Excel</span>
          </button>
          <button
            onClick={() => setShowCouponModal(true)}
            className="bg-violet-600 hover:bg-violet-700 rounded-lg transition-colors duration-200 flex items-center justify-center space-x-3"
          >
            <Gift className="text-white" size={24} />
            <span className="text-white font-medium">Cupón</span>
          </button>
          
          
        </div>

        {/* Filtros */}
        <div className="bg-gray-800/50 backdrop-blur-sm rounded-lg p-6 mb-6">
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="text"
                placeholder="Buscar usuarios..."
                className="w-full pl-10 pr-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="flex items-center space-x-2">
              <Filter size={20} className="text-gray-400" />
              <select
                className="flex-1 bg-gray-700 text-white rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <option value="all">Todos los estados</option>
                <option value="active">Activos</option>
                <option value="suspended">Suspendidos</option>
                <option value="deleted">Eliminados</option>
              </select>
            </div>

            <div className="flex items-center space-x-2">
              <Star size={20} className="text-gray-400" />
              <select
                className="flex-1 bg-gray-700 text-white rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={planFilter}
                onChange={(e) => setPlanFilter(e.target.value)}
              >
                <option value="all">Todos los planes</option>
                <option value="foco">Foco</option>
                <option value="potencia">Potencia</option>
                <option value="brillante">Brillante</option>
                <option value="none">Sin plan</option>
              </select>
            </div>

            <button
              onClick={fetchUserStats}
              className="flex items-center justify-center space-x-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg px-4 py-2 transition-colors duration-200"
            >
              <RefreshCw size={20} />
              <span>Actualizar</span>
            </button>
          </div>
        </div>

        {/* Tabla de Usuarios */}
        <div className="w-full">
          {error && (
            <div className="bg-red-500/10 border border-red-500 text-red-500 p-4 mb-6 rounded-lg">
              {error}
            </div>
          )}

          <div className="w-full overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-800/50 border-b border-gray-700">
                  <th className="w-16 px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider cursor-pointer hover:bg-gray-700/50">
                    <div className="flex items-center space-x-1" onClick={() => handleSort('id')}>
                      <span>ID</span>
                      <ArrowUpDown size={14} />
                    </div>
                  </th>
                  <th className="w-64 px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider cursor-pointer hover:bg-gray-700/50">
                    <div className="flex items-center space-x-1" onClick={() => handleSort('email')}>
                      <span>Email</span>
                      <ArrowUpDown size={14} />
                    </div>
                  </th>
                  <th className="w-64 px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider cursor-pointer hover:bg-gray-700/50">
                    <div className="flex items-center space-x-1" onClick={() => handleSort('name')}>
                      <span>Nombre</span>
                      <ArrowUpDown size={14} />
                    </div>
                  </th>
                  <th className="w-32 px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider cursor-pointer hover:bg-gray-700/50">
                    <div className="flex items-center space-x-1" onClick={() => handleSort('status')}>
                      <span>Estado</span>
                      <ArrowUpDown size={14} />
                    </div>
                  </th>
                  <th className="w-32 px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider cursor-pointer hover:bg-gray-700/50">
                    <div className="flex items-center space-x-1" onClick={() => handleSort('plan_actual')}>
                      <span>Plan Actual</span>
                      <ArrowUpDown size={14} />
                    </div>
                  </th>
                  <th className="w-48 px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider cursor-pointer hover:bg-gray-700/50">
                    <div className="flex items-center space-x-1" onClick={() => handleSort('última_actividad')}>
                      <span>Última Actividad</span>
                      <ArrowUpDown size={14} />
                    </div>
                  </th>
                  <th className="w-40 px-6 py-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                    <span>Acciones</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {filteredUsers.map((user) => (
                  <motion.tr
                    key={user.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="hover:bg-gray-700/30 transition-colors duration-150"
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-white">
                      {user.id}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-white">
                      {user.email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-white">
                      {user.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <StatusBadge status={user.status} />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-3 py-1 rounded-full text-xs font-medium
                        ${user.subscription?.plan === 'brillante' ? 'bg-purple-100 text-purple-800' :
                          user.subscription?.plan === 'potencia' ? 'bg-blue-100 text-blue-800' :
                          user.subscription?.plan === 'foco' ? 'bg-green-100 text-green-800' :
                          'bg-gray-100 text-gray-800'}`}>
                        {user.subscription?.plan || 'Sin plan'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                      {user.last_activity ? 
                        new Date(user.last_activity).toLocaleString() : 
                        'Nunca'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center space-x-2">
                        <button
                          onClick={() => setSelectedUser(user)}
                          className="p-1.5 hover:bg-gray-600/50 rounded-lg transition-colors duration-200"
                          title="Ver detalles"
                        >
                          <UserCog size={18} className="text-blue-400" />
                        </button>
                        <button
                          onClick={() => {
                            setSelectedUserId(user.id);
                            setShowCouponModal(true);
                          }}
                          className="p-1.5 hover:bg-gray-600/50 rounded-lg transition-colors duration-200"
                          title="Gestionar cupón"
                        >
                          <Gift size={18} className="text-violet-400" />
                        </button>
                        <button
                          onClick={() => {
                            setSelectedUserId(user.id);
                            setShowPlanModal(true);
                          }}
                          className="p-1.5 hover:bg-gray-600/50 rounded-lg transition-colors duration-200"
                          title="Cambiar plan"
                        >
                          <Star size={18} className="text-yellow-400" />
                        </button>
                        <button
                          onClick={() => handleUpdateStatus(user.id, 'suspended')}
                          className="p-1.5 hover:bg-gray-600/50 rounded-lg transition-colors duration-200"
                          title="Suspender usuario"
                        >
                          <Ban size={18} className="text-yellow-400" />
                        </button>
                        <button
                          onClick={() => handleDeleteUser(user.id)}
                          className="p-1.5 hover:bg-gray-600/50 rounded-lg transition-colors duration-200"
                          title="Eliminar usuario"
                        >
                          <Trash2 size={18} className="text-red-400" />
                        </button>
                      </div>
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Modales */}
        <AnimatePresence>
        {showCouponModal && (
            <CouponModal 
              onClose={() => setShowCouponModal(false)}
            />
          )}
            />
          )}
          {showCouponListModal && (
            <CouponListModal 
              onClose={() => setShowCouponListModal(false)}
            />
          )}
          {showPlanModal && selectedUserId && (
            <PlanModal 
              userId={selectedUserId}
              onClose={() => {
                setShowPlanModal(false);
                setSelectedUserId(null);
              }}
            />
          )}
          {selectedUser && (
            <UserDetailsModal 
              user={selectedUser}
              onClose={() => setSelectedUser(null)}
            />
          )}
        </AnimatePresence>
      </div>
      <ToastContainer position="bottom-right" />
    </motion.div>
  );
};

export default UserStats;