import React, { useEffect } from 'react';
import { XCircle, AlertTriangle } from 'lucide-react';

const ErrorMessage = ({ message, onClear }) => {
  useEffect(() => {
    if (message) {
      console.log('Error message received:', message);
    }
  }, [message]);

  if (!message) {
    console.log('No error message present');
    return null;
  }

  // Version simplificada para depuración
  return (
    <div 
      className="fixed inset-0 flex items-center justify-center bg-black/50 z-50"
      onClick={onClear}
    >
      <div 
        className="bg-white rounded-lg shadow-xl max-w-md w-full mx-4 p-0"
        onClick={e => e.stopPropagation()}
      >
        <div className="bg-red-500 p-4 rounded-t-lg flex items-center justify-between">
          <div className="flex items-center gap-2 text-white">
            <AlertTriangle />
            <span className="font-semibold">Error</span>
          </div>
          <button 
            onClick={onClear}
            className="text-white hover:opacity-80"
          >
            <XCircle />
          </button>
        </div>
        
        <div className="p-4">
          <p className="text-gray-800">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;