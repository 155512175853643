import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { MessageCircle, X, Send, Bot, AlertCircle, CheckCircle2, Timer } from 'lucide-react';

const PLAN_TICKET_LIMITS = {
  'foco': 5,
  'potencia': 10,
  'brillante': Infinity
};

const TICKET_RESET_PERIOD = 7 * 24 * 60 * 60 * 1000; // 7 días en milisegundos

const FloatingChat = ({ token, userPlan = 'foco' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [awaitingTicketConfirmation, setAwaitingTicketConfirmation] = useState(false);
  const [ticketContext, setTicketContext] = useState('');
  const [ticketsThisWeek, setTicketsThisWeek] = useState(0);
  const [lastTicketReset, setLastTicketReset] = useState(Date.now());
  const [ticketLimit, setTicketLimit] = useState(PLAN_TICKET_LIMITS[userPlan]); // Use PLAN_TICKET_LIMITS instead of hardcoded value
  const [currentPlan, setCurrentPlan] = useState(userPlan);
  const messagesEndRef = useRef(null);

  const getInitialMessage = (plan, limit) => {
    const baseMessage = `¡Hola! Soy Mark, tu asistente personal.\nPuedo ayudarte con:\n\n`;
    
    const features = {
      foco: [
        'Información sobre planes y precios',
        'Estado de tu suscripción',
        `Soporte técnico básico (${limit} tickets/semana)`,
        'Problemas con pagos',
      ],
      potencia: [
        'Información sobre planes y precios',
        'Estado de tu suscripción',
        `Soporte técnico prioritario (${limit} tickets/semana)`,
        'Problemas con pagos',
        'Análisis personalizado',
      ],
      brillante: [
        'Información sobre planes y precios',
        'Estado de tu suscripción',
        'Soporte técnico ilimitado y prioritario',
        'Problemas con pagos',
        'Análisis personalizado',
        'Consultoría directa',
      ],
    };

    const planFeatures = features[plan] || features.foco;
    const formattedFeatures = planFeatures.map(f => `• ${f}`).join('\n');
    
    return `${baseMessage}${formattedFeatures}\n\n¿En qué puedo ayudarte hoy?`;
  };

  useEffect(() => {
    const fetchTicketCount = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/tickets/count`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );
        setTicketsThisWeek(response.data.count);
        setTicketLimit(response.data.limit);
        setCurrentPlan(response.data.plan);
        
        // Solo actualizar el mensaje inicial si aún no hay mensajes
        if (messages.length === 0) {
          setMessages([{
            text: getInitialMessage(response.data.plan, response.data.limit),
            sender: 'bot'
          }]);
        }
      } catch (error) {
        console.error('Error fetching ticket count:', error);
      }
    };

    if (token) fetchTicketCount();
  }, [token, messages.length]);

  // Initialize messages when chat is opened
  useEffect(() => {
    if (!isOpen && messages.length === 0) {
      setMessages([{
        text: getInitialMessage(userPlan, PLAN_TICKET_LIMITS[userPlan]),
        sender: 'bot'
      }]);
    }
  }, [isOpen, userPlan]);

  // Auto-scroll to bottom when new messages arrive
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const canCreateTicket = () => {
    const ticketLimit = PLAN_TICKET_LIMITS[userPlan];
    const timeElapsed = Date.now() - lastTicketReset;
    
    if (timeElapsed >= TICKET_RESET_PERIOD) {
      setTicketsThisWeek(0);
      setLastTicketReset(Date.now());
      return true;
    }

    return ticketsThisWeek < ticketLimit;
  };

  const handleTicketConfirmation = async (userMessage) => {
    const positiveResponses = ['si', 'sí', 'yes', 'ok', 'dale', 'por favor', 'porfavor', 'claro'];
    const negativeResponses = ['no', 'nop', 'nope', 'negativo'];
    
    const normalizedResponse = userMessage.toLowerCase().trim();
    
    if (positiveResponses.some(resp => normalizedResponse.includes(resp))) {
      if (!canCreateTicket()) {
        setMessages(prev => [...prev, {
          text: `Has alcanzado el límite de tickets para tu plan ${userPlan}. 
          ${userPlan !== 'brillante' ? 'Considera actualizar tu plan para obtener más soporte.' : ''}`,
          sender: 'bot'
        }]);
        setAwaitingTicketConfirmation(false);
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/chat`,
          { 
            message: ticketContext,
            create_ticket: true,
            priority: userPlan === 'brillante' ? 'high' : userPlan === 'potencia' ? 'medium' : 'normal'
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        
        setTicketsThisWeek(prev => prev + 1);
        setMessages(prev => [...prev, { 
          text: response.data.reply, 
          sender: 'bot',
          isTicket: true,
          ticketId: response.data.ticketId
        }]);
      } catch (error) {
        setMessages(prev => [...prev, {
          text: 'Lo siento, hubo un error al crear el ticket. Por favor, intenta de nuevo más tarde.',
          sender: 'bot'
        }]);
      }
      setAwaitingTicketConfirmation(false);
      setTicketContext('');
    } else if (negativeResponses.some(resp => normalizedResponse.includes(resp))) {
      setMessages(prev => [...prev, {
        text: 'Entiendo. ¿Hay algo más en lo que pueda ayudarte?',
        sender: 'bot'
      }]);
      setAwaitingTicketConfirmation(false);
      setTicketContext('');
    }
  };

  const renderMessageContent = (message) => {
    if (message.isTicket) {
      return (
        <div className="flex flex-col space-y-2">
          <div className="flex items-center space-x-2">
            <CheckCircle2 className="text-green-500" size={16} />
            <span>Ticket #{message.ticketId} creado</span>
          </div>
          <p>{message.text}</p>
          {userPlan === 'brillante' && (
            <div className="flex items-center space-x-2 text-sm text-purple-400">
              <Timer size={14} />
              <span>Tiempo de respuesta estimado: 2-4 horas</span>
            </div>
          )}
        </div>
      );
    }
    return message.text;
  };

  const sendMessage = async () => {
    if (inputMessage.trim() === '') return;
    
    const userMessage = inputMessage.trim();
    setInputMessage('');
    setMessages(prev => [...prev, { text: userMessage, sender: 'user' }]);

    if (awaitingTicketConfirmation) {
      await handleTicketConfirmation(userMessage);
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/chat`,
        { message: userMessage },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      setMessages(prev => [...prev, { text: response.data.reply, sender: 'bot' }]);

      if (response.data.suggest_ticket) {
        if (!canCreateTicket()) {
          setMessages(prev => [...prev, {
            text: `Has alcanzado el límite de tickets semanales para tu plan ${userPlan}. 
            ${userPlan !== 'brillante' ? 'Considera actualizar tu plan para obtener más soporte.' : ''}`,
            sender: 'bot'
          }]);
          return;
        }

        setAwaitingTicketConfirmation(true);
        setTicketContext(userMessage);
        setMessages(prev => [...prev, {
          text: '¿Quieres que cree un ticket de soporte para que nuestro equipo pueda ayudarte con este problema? (Responde Sí o No)',
          sender: 'bot'
        }]);
      }
    } catch (error) {
      setMessages(prev => [...prev, {
        text: 'Lo siento, hubo un error. Por favor, intenta de nuevo.',
        sender: 'bot'
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {!isOpen && (
        <div className="relative">
          <button
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onClick={() => setIsOpen(true)}
            className={`rounded-full p-4 shadow-lg transition duration-300 flex items-center justify-center
              ${userPlan === 'brillante' 
                ? 'bg-purple-600 hover:bg-purple-700' 
                : userPlan === 'potencia'
                  ? 'bg-blue-600 hover:bg-blue-700'
                  : 'bg-green-600 hover:bg-green-700'
              } text-white`}
          >
            <MessageCircle size={28} />
          </button>
          {showTooltip && (
            <div className="absolute bottom-full right-0 mb-2 bg-gray-800 text-white rounded-lg p-2 shadow-lg text-sm whitespace-nowrap">
              ¡Hola! Soy Mark, tu asistente. ¿Necesitas ayuda?
            </div>
          )}
        </div>
      )}
      
      {isOpen && (
        <div className="bg-gray-900 rounded-lg shadow-2xl w-96 h-[32rem] flex flex-col overflow-hidden">
          <div className={`p-4 flex justify-between items-center
            ${userPlan === 'brillante' 
              ? 'bg-purple-900' 
              : userPlan === 'potencia'
                ? 'bg-blue-900'
                : 'bg-green-900'
            }`}>
            <div className="flex items-center">
              <Bot size={24} className="mr-2 text-white" />
              <div className="flex flex-col">
                <h3 className="font-semibold text-lg text-white">Asistente Mark</h3>
                <span className="text-xs text-gray-300">
                  {ticketsThisWeek}/{ticketLimit === Infinity ? '∞' : ticketLimit} tickets esta semana
                </span>
              </div>
            </div>
            <button 
              onClick={() => setIsOpen(false)} 
              className="text-gray-300 hover:text-white transition duration-150"
            >
              <X size={24} />
            </button>
          </div>

          <div className="flex-grow overflow-y-auto p-4 space-y-4 bg-gray-800">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
              >
                <div
                  className={`max-w-[80%] p-3 rounded-lg ${
                    message.sender === 'user'
                      ? userPlan === 'brillante'
                        ? 'bg-purple-600 text-white'
                        : userPlan === 'potencia'
                          ? 'bg-blue-600 text-white'
                          : 'bg-green-600 text-white'
                      : 'bg-gray-700 text-white'
                  }`}
                >
                  {renderMessageContent(message)}
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
            {isLoading && (
              <div className="flex justify-center">
                <span className="bg-gray-700 text-white px-4 py-2 rounded-lg animate-pulse">
                  Escribiendo...
                </span>
              </div>
            )}
          </div>

          <div className="p-4 bg-gray-800 border-t border-gray-700">
            <div className="flex items-center space-x-2">
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                placeholder="Escribe tu mensaje..."
                className="flex-grow bg-gray-700 text-white rounded-l-lg p-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={sendMessage}
                className={`p-3 rounded-r-lg text-white transition duration-300
                  ${userPlan === 'brillante' 
                    ? 'bg-purple-600 hover:bg-purple-700' 
                    : userPlan === 'potencia'
                      ? 'bg-blue-600 hover:bg-blue-700'
                      : 'bg-green-600 hover:bg-green-700'
                  }`}
              >
                <Send size={20} />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FloatingChat;