import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Linkedin.css'; // Asegúrate de que este archivo exista y contenga los estilos necesarios

const LinkedInComponent = ({ userId, onLogin, onLogout, isLoggedIn, publishContent }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [internalLoggedIn, setInternalLoggedIn] = useState(false);

  useEffect(() => {
    if (userId) {
      checkLoginStatus();
    }
  }, [userId]);

  const apiBaseUrl = process.env.REACT_APP_LINKEDIN_API_URL;

  const checkLoginStatus = async () => {
    if (!userId) {
      console.error('userId is undefined');
      return;
    }
    try {
      const response = await axios.get(`${apiBaseUrl}/api/linkedin/login-status`, {
        params: { user_id: userId }
      });
      if (response.data.isLoggedIn) {
        handleLoginSuccess();
      }
    } catch (err) {
      console.error('Error checking login status:', err);
    }
  };

  const handleLoginSuccess = () => {
    setInternalLoggedIn(true);
    if (typeof onLogin === 'function') {
      onLogin(userId);
    }
  };

  const handleLogin = async () => {
    if (!userId) {
      setError('Error: User ID is not available');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const redirectUrl = `${window.location.origin}/login?user_id=${userId}`;
      const response = await axios.get(`${apiBaseUrl}/api/linkedin/auth-url`, {
        params: { 
          redirect_url: redirectUrl,
          user_id: userId
        }
      });
      window.location.href = response.data.authUrl;
    } catch (err) {
      setError('Error al iniciar sesión con LinkedIn');
      console.error('Error de inicio de sesión:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    if (!userId) {
      console.error('userId is undefined');
      return;
    }
    try {
      await axios.post(`${apiBaseUrl}/api/linkedin/logout`, { user_id: userId });
      setInternalLoggedIn(false);
      if (typeof onLogout === 'function') {
        onLogout(userId);
      }
    } catch (err) {
      console.error('Error logging out:', err);
      setError('Error al cerrar sesión de LinkedIn');
    }
  };

  const handlePublish = async (content) => {
    if (!userId) {
      setError('Error: User ID is not available');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `${apiBaseUrl}/api/linkedin/publish`,
        { content, user_id: userId },
        { withCredentials: true }
      );

      if (response.status === 200) {
        console.log('Contenido publicado en LinkedIn:', response.data);
        if (typeof publishContent === 'function') {
          publishContent(content, 'linkedin');
        }
      } else {
        throw new Error('Error al publicar en LinkedIn');
      }
    } catch (err) {
      setError('Error al publicar en LinkedIn');
      console.error('Error de publicación:', err);
    } finally {
      setLoading(false);
    }
  };

  const isActuallyLoggedIn = isLoggedIn ?? internalLoggedIn;

  return (
    <div className="linkedin-component">
      <div className="linkedin-button-container">
        {!isActuallyLoggedIn ? (
          <button 
            className="linkedin-button linkedin-login-button" 
            onClick={handleLogin} 
            disabled={loading || !userId}
          >
            {loading ? (
              <>
                <span className="loading-spinner"></span>
                Iniciando sesión...
              </>
            ) : (
              <>
                <i className="fab fa-linkedin"></i> Iniciar sesión con LinkedIn
              </>
            )}
          </button>
        ) : (
          <button 
            className="linkedin-button linkedin-logout-button" 
            onClick={handleLogout}
          >
            <i className="fas fa-sign-out-alt"></i> Cerrar sesión de LinkedIn
          </button>
        )}
      </div>
      {error && <p className="linkedin-error-message">{error}</p>}
    </div>
  );
};

export default LinkedInComponent;