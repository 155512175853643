// calendar-utils.js
export const getFrequencyFromUserPreferences = () => {
    try {
      const userPreferences = JSON.parse(localStorage.getItem('userPreferences')) || {};
      return userPreferences.publishFrequency || 'diario';
    } catch (error) {
      console.error('Error al obtener frecuencia de preferencias:', error);
      return 'diario'; // valor por defecto
    }
  };
  
  export const validateEvents = (eventsData) => {
    if (!Array.isArray(eventsData)) {
      console.error('Los eventos recibidos no son un array:', eventsData);
      return [];
    }
    return eventsData
      .map((event) => ({
        ...event,
        start: event.start ? new Date(event.start) : null,
        end: event.end ? new Date(event.end) : null,
      }))
      .filter((event) => event.start && !isNaN(event.start.getTime()));
  };
  
  export const filterEventsByFrequency = (events, frequency) => {
    const sortedEvents = events.sort((a, b) => new Date(a.start) - new Date(b.start));
    
    switch (frequency.toLowerCase()) {
      case 'diario':
        return sortedEvents;
      case 'cada 2 días':
        return sortedEvents.filter((_, index) => index % 2 === 0);
      case 'cada 3 días':
        return sortedEvents.filter((_, index) => index % 3 === 0);
      case 'semanal':
        return sortedEvents.filter((_, index) => index % 7 === 0);
      default:
        return sortedEvents;
    }
  };