import React from 'react';

const LoadingSpinner = ({ message = "Cargando..." }) => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900">
      {/* Spinner Container */}
      <div className="relative">
        {/* Outer spinning gradient ring */}
        <div className="w-24 h-24 rounded-full bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 animate-spin">
          <div className="absolute inset-1 rounded-full bg-gray-900"></div>
        </div>
        
        {/* Inner pulsing circle */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-12 h-12 bg-gradient-to-r from-blue-400 to-purple-500 rounded-full animate-pulse"></div>
        </div>
        
        {/* Center dot */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-4 h-4 bg-white rounded-full"></div>
        </div>
      </div>
      
      {/* Loading Text */}
      <div className="mt-8 space-y-2 text-center">
        <p className="text-lg text-gray-200 font-medium animate-pulse">
          {message}
        </p>
      </div>
    </div>
  );
};

export default LoadingSpinner;