import React, { useState, useEffect } from 'react';
import { Loader } from 'lucide-react';


const RecentPostsSection = ({ userId, onAnswerChange, responses, question, currentSection }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [postInputs, setPostInputs] = useState([{ id: 1, content: '' }]);

  useEffect(() => {
    // Recuperar posts guardados de las respuestas
    if (responses[currentSection]?.[question]?.value) {
      try {
        const savedPosts = JSON.parse(responses[currentSection][question].value);
        if (Array.isArray(savedPosts) && savedPosts.length > 0) {
          setPostInputs(savedPosts);
        }
      } catch (e) {
        // Si el valor guardado no es un array JSON válido, crear un único input con ese valor
        setPostInputs([{
          id: 1,
          content: responses[currentSection][question].value
        }]);
      }
    }
  }, [responses, currentSection, question]);

  const addNewInput = () => {
    const newId = postInputs.length > 0 ? Math.max(...postInputs.map(p => p.id)) + 1 : 1;
    setPostInputs([...postInputs, { id: newId, content: '' }]);
  };

  const removeInput = (id) => {
    if (postInputs.length > 1) {
      const updatedInputs = postInputs.filter(input => input.id !== id);
      setPostInputs(updatedInputs);
      // Actualizar las respuestas
      onAnswerChange(question, JSON.stringify(updatedInputs));
    }
  };

  const handleInputChange = (id, value) => {
    const updatedInputs = postInputs.map(input => 
      input.id === id ? { ...input, content: value } : input
    );
    setPostInputs(updatedInputs);
    // Actualizar las respuestas
    onAnswerChange(question, JSON.stringify(updatedInputs));
  };

  return (
    <div className="space-y-4">
      <div className="text-sm text-gray-300 mb-2">
        📋 Agrega tus últimos posts de LinkedIn
      </div>

      {postInputs.map((input, index) => (
        <div key={input.id} className="relative">
          <div className="flex items-start space-x-2">
            <div className="w-8 h-8 flex items-center justify-center bg-gray-700 rounded-lg text-gray-300">
              {index + 1}
            </div>
            <div className="flex-1">
              <textarea
                className="w-full min-h-[120px] p-3 bg-gray-800 text-gray-100 rounded-lg border border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                placeholder={`Post #${index + 1}...`}
                value={input.content}
                onChange={(e) => handleInputChange(input.id, e.target.value)}
              />
            </div>
            {postInputs.length > 1 && (
              <button
                onClick={() => removeInput(input.id)}
                className="p-2 text-gray-400 hover:text-red-500 transition-colors"
              >
                ✕
              </button>
            )}
          </div>
        </div>
      ))}

      <div className="flex justify-center mt-4">
        <button
          onClick={addNewInput}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          + Agregar otro post
        </button>
      </div>

      {error && (
        <div className="p-4 bg-red-900/50 text-red-200 rounded-lg mt-4">
          {error}
        </div>
      )}

      {loading && (
        <div className="flex justify-center py-4">
          <Loader className="w-6 h-6 text-blue-500 animate-spin" />
        </div>
      )}

      {posts.length > 0 && (
        <div className="mt-6">
          <h4 className="text-lg text-gray-200 mb-4">Posts programados y creados:</h4>
          <div className="space-y-3">
            {posts.map((post) => (
              <div key={post.id} className="p-4 bg-gray-800 rounded-lg border border-gray-700">
                <div className="flex justify-between items-start mb-2">
                  <small className="text-gray-400">
                    {new Date(post.scheduledAt).toLocaleDateString()}
                  </small>
                  <span className={`px-2 py-1 text-xs rounded-full ${
                    post.status === 'completed' 
                      ? 'bg-green-600 text-green-100' 
                      : post.status === 'failed' 
                      ? 'bg-red-600 text-red-100' 
                      : 'bg-gray-600 text-gray-100'
                  }`}>
                    {post.status.charAt(0).toUpperCase() + post.status.slice(1)}
                  </span>
                </div>
                <p className="text-gray-300">{post.content}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RecentPostsSection;