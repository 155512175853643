import React, { useState } from 'react';
import { TrendingUp, Users, Lock } from 'lucide-react';

const LoginForm = ({ onLogin, onSwitch, onForgotPassword, error }) => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');

  const bgStyle = {
    backgroundImage: "url('https://static.vecteezy.com/system/resources/previews/019/588/966/non_2x/liquid-metal-background-wave-of-molten-metal-texture-marble-texture-formed-by-mixing-the-black-and-white-paint-black-wavy-shapes-background-black-and-white-abstractions-with-different-geometric-vector.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed'
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin(usernameOrEmail, password);
  };

  return (
    <div className="min-h-screen bg-gray-900" style={bgStyle}>
      <div className="min-h-screen backdrop-blur-sm bg-black/30">
        {/* Hero Section */}
        <div className="min-h-screen flex flex-col md:flex-row items-center justify-center p-4 md:p-8">
          {/* Left Side - Message */}
          <div className="md:w-1/2 max-w-xl px-4 mb-8 md:mb-0 text-center md:text-left backdrop-blur-md bg-black/20 p-8 rounded-lg">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Bienvenido de nuevo a tu <span className="text-green-400">marca personal</span>
            </h1>
            <p className="text-gray-300 text-lg mb-8">
              Continúa gestionando tu presencia profesional y alcanzando nuevas metas.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex items-center gap-2 text-gray-300">
                <div className="w-10 h-10 rounded-full bg-green-600/20 flex items-center justify-center">
                  <TrendingUp className="text-green-400" size={20} />
                </div>
                <span>Gestión profesional</span>
              </div>
              <div className="flex items-center gap-2 text-gray-300">
                <div className="w-10 h-10 rounded-full bg-green-600/20 flex items-center justify-center">
                  <Users className="text-green-400" size={20} />
                </div>
                <span>Red de contactos</span>
              </div>
            </div>
          </div>

          {/* Right Side - Form */}
          <div className="w-full md:w-1/2 max-w-md px-4">
            <div className="backdrop-blur-md bg-black/50 p-8 rounded-lg shadow-2xl border border-white/10">
              <h2 className="text-3xl font-bold mb-2 text-white">Iniciar Sesión</h2>
              <p className="text-gray-300 text-sm mb-6">
                Accede a tu cuenta para continuar con tu desarrollo profesional
              </p>
              {error && <p className="text-red-400 mb-4">{error}</p>}
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label htmlFor="usernameOrEmail" className="block text-sm font-medium text-gray-300">Correo:</label>
                  <input
                    type="text"
                    id="usernameOrEmail"
                    value={usernameOrEmail}
                    onChange={(e) => setUsernameOrEmail(e.target.value)}
                    required
                    className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm text-white placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500"
                    placeholder="tucorreo@ejemplo.com"
                  />
                </div>
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-300">Contraseña:</label>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm text-white placeholder-gray-400 focus:outline-none focus:ring-green-500 focus:border-green-500"
                    placeholder="••••••••"
                  />
                </div>
                <button type="submit" className="w-full py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-green-500 transition-colors">
                  Iniciar Sesión
                </button>
              </form>
              <div className="mt-6 flex flex-col sm:flex-row justify-between items-center gap-4">
                <button 
                  onClick={onForgotPassword}
                  className="text-sm text-green-400 hover:text-green-300 focus:outline-none transition-colors"
                  type="button"
                >
                  ¿Olvidaste tu contraseña?
                </button>
                <button 
                  onClick={onSwitch} 
                  className="text-sm text-green-400 hover:text-green-300 focus:outline-none transition-colors"
                  type="button"
                >
                  Crear cuenta nueva
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Features Section */}
        <section className="py-16 px-4 backdrop-blur-sm bg-black/30">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-2xl font-bold text-white text-center mb-12">
              ¿Por qué gestionar tu marca personal?
            </h2>
            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  icon: <TrendingUp size={24} />,
                  title: "Mayor visibilidad",
                  description: "Destaca en tu sector y atrae mejores oportunidades profesionales"
                },
                {
                  icon: <Users size={24} />,
                  title: "Red profesional",
                  description: "Construye conexiones valiosas con otros profesionales del sector"
                },
                {
                  icon: <Lock size={24} />,
                  title: "Gestión segura",
                  description: "Administra tu presencia digital de forma segura y profesional"
                }
              ].map((feature, index) => (
                <div key={index} className="bg-black/50 backdrop-blur-md p-6 rounded-lg border border-white/10 hover:border-green-500 transition-colors">
                  <div className="w-12 h-12 bg-green-600/20 rounded-lg flex items-center justify-center mb-4 text-green-400">
                    {feature.icon}
                  </div>
                  <h3 className="text-xl font-semibold text-white mb-2">{feature.title}</h3>
                  <p className="text-gray-300">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default LoginForm;