import React, { useState, useEffect, useRef, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { Smile } from 'lucide-react'; // Most likely replacement
import { Wand2, Upload, Bold, Italic, Underline, List as ListIcon, ListOrdered, Link as LinkIcon, Image as ImageIcon,} from 'lucide-react';
import LinkedInComponent from '../Linkedin/LinkedinInComponent';
import axios from 'axios';
import {
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Checkbox,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  Popover
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DeleteIcon from '@mui/icons-material/Delete';
import esLocale from '@fullcalendar/core/locales/es';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

// Importar las funciones auxiliares
const { getFrequencyFromUserPreferences, validateEvents, filterEventsByFrequency } = require('./calendar-utils');

// Componente para mostrar el estado del evento
const StatusBadge = ({ status }) => {
  const getBadgeStyles = () => {
    switch (status) {
      case 'completed':
        return 'bg-green-100 text-green-800 border-green-200';
      case 'failed':
        return 'bg-red-100 text-red-800 border-red-200';
      default:
        return 'bg-blue-100 text-blue-800 border-blue-200';
    }
  };

  return (
    <span className={`px-2 py-1 text-xs font-medium rounded-full ${getBadgeStyles()} border`}>
      {status === 'completed' ? 'Publicado' : status === 'failed' ? 'Error' : 'Pendiente'}
    </span>
  );
};
const RichEditor = ({ content, onChange, isMobile }) => {
  const editorRef = useRef(null);
  const [activeFormats, setActiveFormats] = useState([]);
  const [storedContent, setStoredContent] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (editorRef.current && content) {
      const htmlContent = typeof content === 'object' ? content.html : content;
      if (htmlContent !== editorRef.current.innerHTML) {
        editorRef.current.innerHTML = htmlContent;
        setStoredContent(htmlContent);
      }
    }
  }, [content]);

  const handleEmojiClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addEmoji = (emoji) => {
    if (editorRef.current) {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const emojiNode = document.createTextNode(emoji.native);
      range.insertNode(emojiNode);
      range.collapse(false);
      handleInput();
    }
    handleClose();
  };

  const handleFormat = (event, format) => {
    event.preventDefault();
    event.stopPropagation();
    
    if (!editorRef.current) return;

    document.execCommand('styleWithCSS', false, true);

    switch (format) {
      case 'bold':
        document.execCommand('bold', false, null);
        break;
      case 'italic':
        document.execCommand('italic', false, null);
        break;
      case 'underline':
        document.execCommand('underline', false, null);
        break;
      case 'bullet':
        document.execCommand('insertUnorderedList', false, null);
        break;
      case 'number':
        document.execCommand('insertOrderedList', false, null);
        break;
      case 'link':
        const url = prompt('Ingresa la URL:');
        if (url) {
          document.execCommand('createLink', false, url);
        }
        break;
      default:
        break;
    }

    handleInput();
    updateActiveFormats();
    editorRef.current.focus();
  };

  const handleInput = () => {
    if (editorRef.current) {
      const currentContent = editorRef.current.innerHTML;
      setStoredContent(currentContent);
      onChange({
        html: currentContent,
        text: editorRef.current.textContent,
        formats: getActiveFormats()
      });
    }
  };

  const getActiveFormats = () => {
    let formats = {};
    if (document.queryCommandState('bold')) formats.bold = true;
    if (document.queryCommandState('italic')) formats.italic = true;
    if (document.queryCommandState('underline')) formats.underline = true;
    return formats;
  };

  const updateActiveFormats = () => {
    const newFormats = [];
    if (document.queryCommandState('bold')) newFormats.push('bold');
    if (document.queryCommandState('italic')) newFormats.push('italic');
    if (document.queryCommandState('underline')) newFormats.push('underline');
    setActiveFormats(newFormats);
  };

  const handleEditorFocus = () => {
    setIsFocused(true);
    updateActiveFormats();
  };

  const handleEditorBlur = (e) => {
    // Modificado: Permitir que el foco salga naturalmente
    const toolbarEl = editorRef.current?.previousElementSibling;
    if (!toolbarEl?.contains(e.relatedTarget)) {
      setIsFocused(false);
      handleInput();
    }
  };

  const handleKeyDown = (e) => {
    // Permitir la navegación natural con Tab
    if (e.key === 'Tab') {
      // No prevenir el comportamiento por defecto
      handleInput(); // Guardar el contenido actual
      setIsFocused(false);
    }
  };

  return (
    <Card 
      variant="outlined" 
      sx={{ 
        border: isFocused ? '2px solid #6366f1' : '1px solid rgba(0, 0, 0, 0.12)',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          boxShadow: '0 2px 8px rgba(0,0,0,0.08)'
        }
      }}
    >
      <div className="bg-gradient-to-r from-indigo-50 to-purple-50 border-b p-2">
        <ToggleButtonGroup 
          value={activeFormats} 
          size="small"
          sx={{
            '& .MuiToggleButton-root': {
              border: 'none',
              borderRadius: '4px !important',
              margin: '0 2px',
              padding: '6px',
              '&.Mui-selected': {
                backgroundColor: 'rgba(99, 102, 241, 0.1)',
                color: '#6366f1',
              },
              '&:hover': {
                backgroundColor: 'rgba(99, 102, 241, 0.05)',
              }
            }
          }}
        >
          <ToggleButton value="bold" onClick={(e) => handleFormat(e, 'bold')} selected={activeFormats.includes('bold')}>
            <Bold size={18} />
          </ToggleButton>
          <ToggleButton value="italic" onClick={(e) => handleFormat(e, 'italic')} selected={activeFormats.includes('italic')}>
            <Italic size={18} />
          </ToggleButton>
          <ToggleButton value="underline" onClick={(e) => handleFormat(e, 'underline')} selected={activeFormats.includes('underline')}>
            <Underline size={18} />
          </ToggleButton>

          <Divider orientation="vertical" flexItem sx={{ mx: 1, my: 0.5 }} />

          <ToggleButton value="bullet" onClick={(e) => handleFormat(e, 'bullet')}>
            <ListIcon size={18} />
          </ToggleButton>
          <ToggleButton value="number" onClick={(e) => handleFormat(e, 'number')}>
            <ListOrdered size={18} />
          </ToggleButton>

          <Divider orientation="vertical" flexItem sx={{ mx: 1, my: 0.5 }} />

          <ToggleButton value="link" onClick={(e) => handleFormat(e, 'link')}>
            <LinkIcon size={18} />
          </ToggleButton>
          <ToggleButton value="emoji" onClick={handleEmojiClick}>
            <Smile size={18} />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <div
        ref={editorRef}
        contentEditable
        className={`p-4 min-h-[200px] focus:outline-none prose prose-lg max-w-none
          ${isFocused ? 'bg-white' : 'bg-gray-50'}`}
        onInput={handleInput}
        onFocus={handleEditorFocus}
        onBlur={handleEditorBlur}
        onKeyDown={handleKeyDown}
        spellCheck="true"
        tabIndex="0"
        style={{
          direction: 'ltr',
          unicodeBidi: 'embed',
          textAlign: 'left',
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          fontSize: '1rem',
          lineHeight: '1.5',
          color: '#374151',
          transition: 'background-color 0.2s ease-in-out'
        }}
      />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 8,
          sx: {
            borderRadius: '12px',
            border: '1px solid rgba(0,0,0,0.1)',
          }
        }}
      >
        <Picker 
          onSelect={addEmoji} 
          title="Selecciona un emoji" 
          emoji="point_up" 
          theme="light"
          showPreview={false}
          showSkinTones={false}
        />
      </Popover>
    </Card>
  );
};

const RichEditDialog = ({
  open,
  onClose,
  tempEvent,
  onEventChange,
  onSubmit,
  onImageUpload,
  selectedImage,
  isMobile
}) => {
  const [enhancementPrompt, setEnhancementPrompt] = useState('');
  const [isEnhancing, setIsEnhancing] = useState(false);
  const [editorContent, setEditorContent] = useState('');
  const editorRef = useRef(null);
  const [showTitleError, setShowTitleError] = useState(false);
  const titleRef = useRef(null);
const hashtagsRef = useRef(null);


  // Check if there's existing content
  const hasExistingContent = tempEvent?.content && 
    ((typeof tempEvent.content === 'object' && tempEvent.content.html?.trim()) || 
     (typeof tempEvent.content === 'string' && tempEvent.content.trim()));
     const forceEditorBlur = () => {
      if (editorRef.current) {
        editorRef.current.blur();
        window.getSelection().removeAllRanges();
      }
    };

    const handleHashtagsChange = (e) => {
      forceEditorBlur();
      onEventChange('hashtags', e.target.value);
    };
    
    const handleHashtagsFocus = () => {
      forceEditorBlur();
    };
  // Limpiar estados cuando se abre/cierra el diálogo
  useEffect(() => {
    if (!open) {
      setEnhancementPrompt('');
      setShowTitleError(false);
      setEditorContent('');
    } else {
      setEditorContent(
        typeof tempEvent?.content === 'object' 
          ? tempEvent.content.html 
          : tempEvent?.content || ''
      );
    }
  }, [open, tempEvent]);

  const handleContentChange = (content) => {
    setEditorContent(content.html);
    onEventChange('content', content);

    // Generar título automáticamente si no existe
    if (!tempEvent?.title?.trim() && content.text?.trim()) {
      const firstLine = content.text.split('\n')[0].trim();
      const title = firstLine.length > 60 ? `${firstLine.substring(0, 57)}...` : firstLine;
      onEventChange('title', title);
      setShowTitleError(false);
    }
  };

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    onEventChange('title', newTitle);
    setShowTitleError(!newTitle.trim() && hasContentButNoTitle());
  };

  const hasContentButNoTitle = () => {
    return hasExistingContent && !tempEvent?.title?.trim();
  };

  const canSave = () => {
    const hasTitle = tempEvent?.title?.trim();
    const hasContent = tempEvent?.content && (
      (typeof tempEvent.content === 'object' && tempEvent.content.html?.trim()) ||
      (typeof tempEvent.content === 'string' && tempEvent.content.trim())
    );
    
    return hasTitle && hasContent;
  };

  const handleEnhanceContent = async () => {
    if (!enhancementPrompt) return;

    setIsEnhancing(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/enhance-post`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          content: editorContent || '',
          prompt: enhancementPrompt,
          isNewContent: !hasExistingContent
        })
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Error al procesar el contenido');
      }

      if (data.enhanced_content) {
        const enhancedContent = {
          html: data.enhanced_content,
          text: data.enhanced_content
        };
        handleContentChange(enhancedContent);
        setEnhancementPrompt('');
      }
    } catch (error) {
      console.error('Error:', error);
      alert(error.message || 'Error al procesar el contenido');
    } finally {
      setIsEnhancing(false);
    }
  };

  const handleSubmit = () => {
    if (!tempEvent?.title?.trim()) {
      setShowTitleError(true);
      return;
    }
    onSubmit();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      fullWidth 
      maxWidth="lg"
      PaperProps={{
        sx: {
          minHeight: '80vh'
        }
      }}
    >
      <DialogTitle>
        {tempEvent?.id ? 'Editar publicación' : 'Nueva publicación'}
      </DialogTitle>

      <DialogContent dividers>
        <div className={`flex flex-col ${isMobile ? '' : 'md:flex-row'} gap-6`}>
          <div className="w-full">
            {/* Título */}
            <div className="mb-4">
              <TextField
                label="Título"
                value={tempEvent?.title || ''}
                onChange={handleTitleChange}
                error={showTitleError}
                helperText={showTitleError ? 'Se requiere un título' : ''}
                fullWidth
                required
                variant="outlined"
              />
            </div>

            {/* AI Enhancement Section */}
            <div className="mb-4 bg-white-800 p-4 rounded-lg border border-gray-700">
              <div className="flex gap-4">
                <TextField
                  label={hasExistingContent ? 
                    "¿Qué quieres mejorar del post?" : 
                    "¿Qué tipo de post quieres crear?"
                  }
                  value={enhancementPrompt}
                  onChange={(e) => setEnhancementPrompt(e.target.value)}
                  placeholder={hasExistingContent ?
                    "Ej: Hazlo más persuasivo, añade más emojis, mejora los hashtags..." :
                    "Ej: Un post promocional sobre un nuevo producto, una reflexión sobre liderazgo..."
                  }
                  fullWidth
                  size="small"
                  className="flex-grow"
                />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleEnhanceContent}
                  disabled={isEnhancing || !enhancementPrompt}
                  startIcon={<Wand2 className={isEnhancing ? 'animate-spin' : ''} size={20} />}
                >
                  {isEnhancing ? 'Procesando...' : hasExistingContent ? 'Mejorar' : 'Crear'}
                </Button>
              </div>
              <div className="mt-2 text-xs text-gray-400">
                {hasExistingContent ?
                  "Usa IA para mejorar el contenido de tu post según tus necesidades" :
                  "Usa IA para crear un nuevo post profesional para LinkedIn"
                }
              </div>
            </div>

            {/* Editor */}
            <div className="mb-4">
              <RichEditor
                content={tempEvent?.content}
                onChange={handleContentChange}
                isMobile={isMobile}
              />
            </div>

            {/* Hashtags */}
            <div className="mb-4">
              <TextField
                label="Hashtags"
                value={tempEvent?.hashtags || ''}
                onChange={(e) => onEventChange('hashtags', e.target.value)}
                fullWidth
                placeholder="#TusHashtags"
                variant="outlined"
              />
            </div>

            {/* Imagen */}
            <div className="mb-4">
              <input
                type="file"
                accept="image/*"
                onChange={onImageUpload}
                style={{ display: 'none' }}
                id="edit-image-upload"
              />
              <label htmlFor="edit-image-upload">
                <Button
                  variant="outlined"
                  component="span"
                  startIcon={<Upload />}
                  fullWidth
                >
                  {selectedImage ? 'Cambiar imagen' : 'Subir imagen'}
                </Button>
              </label>
              {selectedImage && (
                <div className="mt-2">
                  <img
                    src={selectedImage}
                    alt="Preview"
                    className="max-h-32 rounded"
                  />
                </div>
              )}
            </div>
          </div>

          {/* Vista previa */}
          <div className="w-full bg-gray-50 p-4 rounded">
            <div className="mb-4">
              <strong>Vista Previa:</strong>
            </div>
            <div className="bg-white p-4 rounded shadow">
              <div className="flex items-center mb-4">
                <div className="w-12 h-12 bg-gray-300 rounded-full mr-4"></div>
                <div>
                  <div className="font-semibold">
                    {tempEvent?.title || 'Título de la publicación'}
                  </div>
                  <div className="text-sm text-gray-500">
                    {tempEvent?.start ? new Date(tempEvent.start).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit'
                    }) : ''} • 🌐
                  </div>
                </div>
              </div>

              <div
                className="prose prose-sm"
                dangerouslySetInnerHTML={{
                  __html: typeof tempEvent?.content === 'object'
                    ? tempEvent.content.html
                    : tempEvent?.content || 'Tu contenido aparecerá aquí'
                }}
              />

              {tempEvent?.hashtags && (
                <div className="mt-2 text-sm text-indigo-600">
                  {tempEvent.hashtags}
                </div>
              )}

              {(tempEvent?.image || selectedImage) && (
                <div className="relative mt-4">
                  <img
                    src={selectedImage || tempEvent?.image}
                    alt="Preview"
                    className="w-full h-64 object-cover rounded"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button 
          onClick={handleSubmit} 
          variant="contained" 
          color="primary"
          disabled={!canSave()}
        >
          {showTitleError ? 'Añadir título' : 'Guardar cambios'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
// Componente principal del calendario
const Calendar = ({
  events: initialEvents,
  savedPlans,
  token,
  onEventUpdate,
  selectedEvent,
  isGoogleApiReady,
  isSignedIn,
  userId,
}) => {
  // Estados
  const [events, setEvents] = useState(initialEvents);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isNewEventDialogOpen, setIsNewEventDialogOpen] = useState(false);
  const [isEditEventDialogOpen, setIsEditEventDialogOpen] = useState(false);
  const [calendarKey, setCalendarKey] = useState(0);
  const [editingEvent, setEditingEvent] = useState(null);
  const [tempEditingEvent, setTempEditingEvent] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [scheduledPosts, setScheduledPosts] = useState([]);
  const [newEvent, setNewEvent] = useState({
    title: '',
    content: '',
    time: new Date(),
    image: null,
    hashtags: '',
  });
  const [newEventDate, setNewEventDate] = useState(null);

  // Hooks para pantalla
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const calendarRef = useRef(null);
  const [currentView, setCurrentView] = useState(isMobile ? 'listWeek' : 'dayGridMonth');

  // Actualizar la vista del calendario cuando cambia el tamaño de la pantalla
  useEffect(() => {
    setCurrentView(isMobile ? 'listWeek' : 'dayGridMonth');
  }, [isMobile]);

  // Función para obtener la frecuencia de preferencias del usuario
  const getFrequencyFromUserPreferences = () => {
    try {
      const userPreferences = JSON.parse(localStorage.getItem('userPreferences')) || {};
      return userPreferences.publishFrequency || 'diario';
    } catch (error) {
      console.error('Error al obtener frecuencia de preferencias:', error);
      return 'diario'; // valor por defecto
    }
  };

  // Función para validar eventos
  const validateEvents = (eventsData) => {
    if (!Array.isArray(eventsData)) {
      console.error('Los eventos recibidos no son un array:', eventsData);
      return [];
    }
    return eventsData
      .map((event) => ({
        ...event,
        start: event.start ? new Date(event.start) : null,
        end: event.end ? new Date(event.end) : null,
      }))
      .filter((event) => event.start && !isNaN(event.start.getTime()));
  };

  // Función para filtrar eventos por frecuencia
  const filterEventsByFrequency = (events, frequency) => {
    const sortedEvents = events.sort((a, b) => new Date(a.start) - new Date(b.start));
    
    switch (frequency.toLowerCase()) {
      case 'diario':
        return sortedEvents;
      case 'cada 2 días':
        return sortedEvents.filter((_, index) => index % 2 === 0);
      case 'cada 3 días':
        return sortedEvents.filter((_, index) => index % 3 === 0);
      case 'semanal':
        return sortedEvents.filter((_, index) => index % 7 === 0);
      default:
        return sortedEvents;
    }
  };

  // Función para obtener el color del evento
  const getEventColor = (event) => {
    const scheduledPost = scheduledPosts.find(
      (post) => post.id.toString() === event.id.toString()
    );

    if (scheduledPost) {
      switch (scheduledPost.status) {
        case 'completed':
          return '#22c55e';
        case 'failed':
          return '#ef4444';
        case 'pending':
          return '#3b82f6';
        default:
          return '#3b82f6';
      }
    }

    return '#6366f1';
  };

  // Función para obtener posts programados
  const fetchScheduledPosts = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LINKEDIN_API_URL}/api/linkedin/scheduled-posts`,
        {
          params: { user_id: userId },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('linkedinToken')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data && Array.isArray(response.data)) {
        setScheduledPosts(response.data);

        setEvents((prevEvents) => {
          return prevEvents.map((event) => {
            const scheduledPost = response.data.find((post) => post.id === event.id);
            if (scheduledPost) {
              return {
                ...event,
                extendedProps: {
                  ...event.extendedProps,
                  status: scheduledPost.status,
                  scheduledAt: scheduledPost.scheduledAt,
                },
              };
            }
            return event;
          });
        });

        setCalendarKey((prevKey) => prevKey + 1);
      }
    } catch (error) {
      console.error('Error al obtener posts programados:', error);
    }
  }, [userId]);

  // Efecto para manejar eventos iniciales
  useEffect(() => {
    if (!Array.isArray(initialEvents)) {
      console.error('Los eventos recibidos no son un array:', initialEvents);
      return;
    }

    setEvents((prevEvents) => {
      const uniqueEventsMap = new Map();
      const combinedEvents = [...prevEvents, ...initialEvents];

      combinedEvents.forEach((event) => {
        // Asegurarse de que el contenido formateado se preserve
        const existingEvent = uniqueEventsMap.get(event.id);
        if (existingEvent && typeof event.extendedProps?.content === 'object') {
          // Si el evento existente tiene contenido formateado, mantenerlo
          event.extendedProps.content = event.extendedProps.content;
        }
        uniqueEventsMap.set(event.id, event);
      });

      const frequency = getFrequencyFromUserPreferences();
      const filtered = filterEventsByFrequency(
        validateEvents(Array.from(uniqueEventsMap.values())), 
        frequency
      );
      setFilteredEvents(filtered);

      return Array.from(uniqueEventsMap.values());
    });

    fetchScheduledPosts();
  }, [initialEvents, fetchScheduledPosts]);

  // Función para actualizar eventos
  const handleEventUpdate = useCallback(async (updatedEvent) => {
    try {
      const eventsToUpdate = Array.isArray(updatedEvent) ? updatedEvent : [updatedEvent];
      const eventMap = new Map();

      const formattedEvents = eventsToUpdate
        .map((event) => {
          const eventId = event.id;
          if (eventMap.has(eventId)) return null;

          // Procesar el contenido teniendo en cuenta el formato almacenado
          const content = event.extendedProps?.content;
          const formattedContent = typeof content === 'object' ? content : { html: content, formats: {} };

          const formatted = {
            id: eventId,
            title: event.title,
            start: event.start instanceof Date ? event.start.toISOString() : new Date(event.start).toISOString(),
            end: event.end instanceof Date ? event.end.toISOString() : new Date(event.end).toISOString(),
            content: formattedContent,
            htmlContent: typeof content === 'object' ? content.html : content,
            hashtags: event.extendedProps?.hashtags || '',
            platform: event.extendedProps?.platform || 'LinkedIn',
            image: event.extendedProps?.image || null,
          };

          eventMap.set(eventId, formatted);
          return formatted;
        })
        .filter(Boolean);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/save-calendar`,
        { events: formattedEvents },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        setEvents((prevEvents) => {
          const newEventsMap = new Map();

          prevEvents.forEach((event) => {
            if (!eventMap.has(event.id)) {
              newEventsMap.set(event.id, event);
            }
          });

          formattedEvents.forEach((event) => {
            newEventsMap.set(event.id, {
              ...event,
              start: new Date(event.start),
              end: new Date(event.end),
              extendedProps: {
                content: event.content,
                hashtags: event.hashtags,
                platform: event.platform,
                image: event.image,
              },
            });
          });

          return Array.from(newEventsMap.values());
        });

        return true;
      }

      throw new Error('Error al actualizar los eventos');
    } catch (error) {
      console.error('Error en handleEventUpdate:', error);
      return false;
    }
  }, [token]);

  // Funciones de gestión de eventos
  const handleEventSelection = (event) => {
    setSelectedEvents((prevSelected) => {
      const isAlreadySelected = prevSelected.some((e) => e.id === event.id);
      if (isAlreadySelected) {
        return prevSelected.filter((e) => e.id !== event.id);
      } else {
        return [...prevSelected, event];
      }
    });
  };

  const handleCancelPost = async (postId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_LINKEDIN_API_URL}/api/linkedin/cancel-post/${postId}`,
        {
          params: { user_id: userId },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('linkedinToken')}`,
          },
        }
      );

      setScheduledPosts((prevPosts) =>
        prevPosts.filter((post) => post.id.toString() !== postId.toString())
      );

      setEvents((prevEvents) => {
        return prevEvents.map((event) => {
          if (event.id.toString() === postId.toString()) {
            return {
              ...event,
              extendedProps: {
                ...event.extendedProps,
                status: undefined,
                scheduledAt: undefined,
              },
            };
          }
          return event;
        });
      });

      alert('Post cancelado exitosamente');
      await fetchScheduledPosts();
    } catch (error) {
      console.error('Error cancelando post:', error);
      setError('Error al cancelar el post');
    }
  };

  // Función para renderizar el contenido del evento
  const renderEventContent = (eventInfo) => {
    const { event } = eventInfo;
    const isSelected = selectedEvents.some((e) => e.id === event.id);
    const scheduledPost = scheduledPosts.find(
      (post) => post.id.toString() === event.id.toString()
    );
    const hasImage = event.extendedProps?.image;
    const isPast = new Date(event.start) < new Date();

    let status = 'pending';
    if (scheduledPost) {
      status = scheduledPost.status || 'pending';
    }

    return (
      <div
        className={`p-1 rounded w-full transition-all duration-200 shadow-sm overflow-hidden
                    ${isSelected ? 'bg-opacity-90' : 'bg-opacity-75'}
                    ${isPast ? 'opacity-40' : ''}`}
        style={{ backgroundColor: getEventColor(event) }}
      >
        <div className="flex items-center justify-between">
          <Checkbox
            checked={isSelected}
            onChange={() => handleEventSelection(event)}
            onClick={(e) => e.stopPropagation()}
            className="text-white"
            disabled={isPast}
          />
          {scheduledPost && <StatusBadge status={status} />}
        </div>

        <div className={`text-xs font-semibold mb-1 truncate text-white ${isMobile ? 'text-sm' : ''}`}>
          {event.title}
        </div>

        <div className="text-xxs text-white/80 truncate">
          {event.extendedProps.platform || 'LinkedIn'}
          {scheduledPost && (
            <span className="ml-2">
              {new Date(scheduledPost.scheduledAt).toLocaleTimeString('es-ES', {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </span>
          )}
        </div>

        {hasImage && (
          <div className="w-full h-20 relative overflow-hidden rounded mt-1">
            <img
              src={event.extendedProps.image}
              alt="Post content"
              className="w-full h-full object-cover"
            />
          </div>
        )}

        {scheduledPost && !isPast && (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleCancelPost(event.id);
            }}
            className="mt-1 text-white hover:text-red-500"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    );
  };

  // Funciones para manejar nuevos eventos
  const handleNewEventChange = (field, value) => {
    setNewEvent((prev) => ({ ...prev, [field]: value }));
  };

  const handleNewEventSubmit = () => {
    if (!newEventDate) {
      setError('Por favor, selecciona una fecha para el evento.');
      return;
    }

    const eventDateTime = new Date(newEventDate);
    const timeDate = new Date(newEvent.time);

    eventDateTime.setHours(timeDate.getHours());
    eventDateTime.setMinutes(timeDate.getMinutes());
    eventDateTime.setSeconds(0);
    eventDateTime.setMilliseconds(0);

    const newEventObject = {
      id: Date.now().toString(),
      title: newEvent.title,
      start: eventDateTime,
      end: new Date(eventDateTime.getTime() + 60 * 60 * 1000),
      extendedProps: {
        content: newEvent.content,
        hashtags: newEvent.hashtags,
        platform: 'LinkedIn',
        image: newEvent.image,
      },
    };

    setEvents((prevEvents) => [...prevEvents, newEventObject]);
    setIsNewEventDialogOpen(false);
    setNewEvent({
      title: '',
      content: '',
      time: new Date(),
      image: null,
      hashtags: '',
    });
    setSelectedImage(null);

    handleEventUpdate(newEventObject);
  };

  // Funciones para editar eventos existentes
  const handleEditEventChange = (field, value) => {
    setTempEditingEvent((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleEditEventSubmit = async () => {
    if (!tempEditingEvent) return;

    const formattedEvent = {
      id: tempEditingEvent.id,
      title: tempEditingEvent.title,
      start: tempEditingEvent.start,
      end: tempEditingEvent.end,
      extendedProps: {
        content: tempEditingEvent.content,
        hashtags: tempEditingEvent.hashtags,
        platform: 'LinkedIn',
        image: tempEditingEvent.image,
      },
    };

    setEvents((prevEvents) => {
      return prevEvents.map((event) =>
        event.id === formattedEvent.id ? formattedEvent : event
      );
    });

    setEditingEvent(formattedEvent);
    setIsEditEventDialogOpen(false);

    const success = await handleEventUpdate(formattedEvent);
    if (success) {
      alert('Evento actualizado y guardado exitosamente.');
    } else {
      alert('Error al guardar el evento. Por favor, inténtalo de nuevo.');
    }
  };

  // Función para programar posts en LinkedIn
  // Modificar la función processContentForLinkedin para mantener el formato original
  const processContentForLinkedin = (content) => {
    // Si el contenido es un objeto con formato almacenado
    if (typeof content === 'object' && content.html) {
      // Devolver directamente el HTML sin procesar
      return content.html;
    }
    
    // Si es una cadena de texto simple
    return content;
  };

  // Función para extraer solo el texto plano del HTML
  const getTextContentFromHTML = (content) => {
    if (typeof content === 'object' && content.html) {
      // Crear un elemento temporal para extraer el texto
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = content.html;
      
      // Reemplazar etiquetas comunes con saltos de línea
      const htmlContent = tempDiv.innerHTML
        .replace(/<\/p>/g, '\n')
        .replace(/<br\s*\/?>/g, '\n')
        .replace(/<\/div>/g, '\n');
      
      // Eliminar todas las etiquetas HTML restantes
      tempDiv.innerHTML = htmlContent;
      return tempDiv.textContent.trim();
    }
    return content;
  };

  const handleBatchLinkedInPublish = async () => {
    if (selectedEvents.length === 0) {
      setError('No hay eventos seleccionados para publicar');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const linkedinToken = localStorage.getItem('linkedinToken');
      const postsToSchedule = selectedEvents.map((event) => {
        const content = event.extendedProps?.content;
        const hashtags = event.extendedProps?.hashtags;
        
        // Obtener el texto limpio
        const cleanContent = getTextContentFromHTML(content);
        
        // Agregar hashtags si existen
        const finalContent = hashtags 
          ? `${cleanContent}\n\n${hashtags}` 
          : cleanContent;

        return {
          id: event.id,
          content: finalContent,
          scheduledAt: event.start instanceof Date ? event.start.toISOString() : event.start,
          image: event.extendedProps?.image,
          hashtags: hashtags
        };
      });

      const response = await axios.post(
        `${process.env.REACT_APP_LINKEDIN_API_URL}/api/linkedin/batch-schedule`,
        {
          posts: postsToSchedule,
          user_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${linkedinToken}`,
            'Content-Type': 'application/json',
          },
          timeout: 30000,
        }
      );

      if (response.status === 200 && response.data.scheduled_posts) {
        setEvents((prevEvents) => {
          return prevEvents.map((event) => {
            const matchingPost = response.data.scheduled_posts.find(
              (post) => post.id === event.id
            );

            if (matchingPost) {
              return {
                ...event,
                extendedProps: {
                  ...event.extendedProps,
                  status: 'pending',
                  scheduledAt: matchingPost.scheduledAt,
                },
              };
            }
            return event;
          });
        });

        const newScheduledPosts = response.data.scheduled_posts.map((post) => ({
          ...post,
          status: 'pending',
        }));
        setScheduledPosts((prevPosts) => [...prevPosts, ...newScheduledPosts]);

        alert(`${response.data.scheduled_posts.length} publicaciones programadas exitosamente`);
        setSelectedEvents([]);
        setCalendarKey((prevKey) => prevKey + 1);
        await fetchScheduledPosts();
      }
    } catch (error) {
      console.error('Error al programar posts:', error);
      setError('Error al programar en LinkedIn: ' + (error.response?.data?.error || error.message));
    } finally {
      setIsLoading(false);
    }
  };

  // Función para publicar inmediatamente en LinkedIn
  const handleImmediatePublish = async () => {
    if (selectedEvents.length === 0) {
      setError('No hay eventos seleccionados para publicar inmediatamente');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const currentTime = new Date();
      currentTime.setSeconds(currentTime.getSeconds() + 30);

      const linkedinToken = localStorage.getItem('linkedinToken');
      const postsToPublish = selectedEvents.map((event) => ({
        id: event.id,
        content: processContentForLinkedin(event.extendedProps?.content || ''),
        scheduledAt: currentTime.toISOString(),
        image: event.extendedProps?.image,
        hashtags: event.extendedProps?.hashtags,
      }));

      const response = await axios.post(
        `${process.env.REACT_APP_LINKEDIN_API_URL}/api/linkedin/batch-schedule`,
        {
          posts: postsToPublish,
          user_id: userId,
          immediate: true,
        },
        {
          headers: {
            Authorization: `Bearer ${linkedinToken}`,
            'Content-Type': 'application/json',
          },
          timeout: 30000,
        }
      );

      if (response.status === 200) {
        alert('Publicaciones realizadas exitosamente');
        setSelectedEvents([]);
        await fetchScheduledPosts();
      }
    } catch (error) {
      console.error('Error en la publicación inmediata:', error);
      setError('Error al publicar: ' + (error.response?.data?.error || error.message));
    } finally {
      setIsLoading(false);
    }
  };

  // Función para manejar el clic en un evento
  const handleEventClick = (clickInfo) => {
    const event = clickInfo.event;
    const eventData = {
      id: event.id,
      title: event.title,
      content: event.extendedProps.content,
      hashtags: event.extendedProps.hashtags,
      start: event.start,
      end: event.end,
      image: event.extendedProps.image || null,
    };
    setEditingEvent(eventData);
    setTempEditingEvent(eventData);
    setIsEditEventDialogOpen(true);
  };

  // Función para manejar el clic en una fecha (crear nuevo evento)
  const handleDateClick = (arg) => {
    setNewEventDate(arg.date);
    setNewEvent({
      title: '',
      content: '',
      time: new Date(),
      image: null,
      hashtags: '',
    });
    setIsNewEventDialogOpen(true);
  };

  // Función para manejar el movimiento de eventos (drag & drop)
  const handleEventDrop = async (eventDropInfo) => {
    const { event, oldEvent } = eventDropInfo;
    if (!isSameMonth(oldEvent.start, event.start)) {
      eventDropInfo.revert();
      alert('Los eventos solo pueden moverse dentro del mismo mes.');
      return;
    }

    const updatedEvent = {
      ...event.toPlainObject(),
      start: event.start,
      end: event.end,
    };
    await handleEventUpdate(updatedEvent);
  };

  // Función para manejar el cambio de tamaño de eventos (resize)
  const handleEventResize = async (eventResizeInfo) => {
    const updatedEvent = {
      ...eventResizeInfo.event.toPlainObject(),
      start: eventResizeInfo.event.start,
      end: eventResizeInfo.event.end,
    };
    await handleEventUpdate(updatedEvent);
  };

  // Función para verificar si dos fechas están en el mismo mes y año
  const isSameMonth = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return (
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear()
    );
  };

  // Función para manejar la carga de imágenes
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (file.size > 5 * 1024 * 1024) { // 5MB
          setError('La imagen es demasiado grande. Por favor, selecciona una imagen menor a 5MB.');
          return;
        }
        const imageData = reader.result;
        setSelectedImage(imageData);
        if (isEditEventDialogOpen) {
          setTempEditingEvent((prev) => ({
            ...prev,
            image: imageData,
          }));
        } else {
          setNewEvent((prev) => ({
            ...prev,
            image: imageData,
          }));
        }
      };
      reader.readAsDataURL(file);
    }
  };

  // Modificación del useEffect para el manejo de vistas
  useEffect(() => {
    const updateCalendarView = async () => {
      try {
        if (!calendarRef.current) return;
        
        const calendarApi = calendarRef.current.getApi();
        const newView = isMobile ? 'listMonth' : 'dayGridMonth';
        
        // Primero actualizamos el estado local
        setCurrentView(newView);
        
        // Luego actualizamos la vista del calendario con un pequeño retraso
        // para asegurar que el DOM está listo
        setTimeout(() => {
          if (calendarApi && calendarApi.view.type !== newView) {
            calendarApi.changeView(newView);
            // Forzar un re-render del calendario
            calendarApi.render();
          }
        }, 0);
      } catch (error) {
        console.error('Error al cambiar la vista del calendario:', error);
      }
    };

    updateCalendarView();
  }, [isMobile]);

  return (
    <div className="container mx-auto px-4 bg-gradient-to-br from-indigo-100 to-purple-100 text-black rounded-xl shadow-2xl p-8 my-10">
      <LinkedInComponent userId={userId} />
  
      {/* Botones de acción principales */}
      <div className="mb-6 flex flex-wrap justify-between items-center gap-4">
        <div className="flex space-x-2">
          <Button
            variant="contained"
            color="primary"
            onClick={() => setSelectedEvents(filteredEvents)}
            size={isMobile ? "small" : "medium"}
          >
            Seleccionar Todos
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setSelectedEvents([])}
            size={isMobile ? "small" : "medium"}
          >
            Deseleccionar Todos
          </Button>
        </div>
  
        <div className="flex space-x-2">
          <Button
            variant="contained"
            color="success"
            onClick={handleBatchLinkedInPublish}
            disabled={isLoading || selectedEvents.length === 0}
            startIcon={<Upload />}
            size={isMobile ? "small" : "medium"}
          >
            {isLoading ? 'Programando...' : `Programar (${selectedEvents.length})`}
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={handleImmediatePublish}
            disabled={isLoading || selectedEvents.length === 0}
            startIcon={<Upload />}
            size={isMobile ? "small" : "medium"}
          >
            {isLoading ? 'Publicando...' : `Publicar (${selectedEvents.length})`}
          </Button>
        </div>
      </div>
  
      {/* Mensaje de error */}
      {error && (
        <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          <span className="block sm:inline">{error}</span>
        </div>
      )}
  
      {/* Calendario */}
      <div className="mb-8 bg-white rounded-xl shadow-lg overflow-hidden">
  
<FullCalendar
  key={`${calendarKey}-${currentView}`} // Añadir la vista actual a la key
  ref={calendarRef}
  plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
  initialView={currentView}
  events={events}
  editable={true}
  locale={esLocale}
  selectable={true}
  eventClick={handleEventClick}
  eventDrop={handleEventDrop}
  eventResize={handleEventResize}
  eventContent={renderEventContent}
  dateClick={handleDateClick}
  height="auto"
  aspectRatio={1.5}
  headerToolbar={{
    left: 'prev,next today',
    center: 'title',
    right: isMobile ? '' : 'dayGridMonth,listMonth',
  }}
  views={{
    listMonth: { 
      buttonText: 'Lista',
      duration: { months: 1 },
      listDayFormat: { weekday: 'long', day: 'numeric', month: 'long' },
      listDaySideFormat: { weekday: 'short' }
    },
    dayGridMonth: { 
      buttonText: 'Mes',
      fixedWeekCount: false, // Permite que el número de semanas se ajuste automáticamente
      showNonCurrentDates: false // Oculta los días que no pertenecen al mes actual
    }
  }}
  // Eliminar defaultView y usar la vista inicial
  viewDidMount={(args) => {
    // Asegurarse que la vista corresponda al dispositivo
    if ((isMobile && args.view.type !== 'listMonth') || 
        (!isMobile && args.view.type !== 'dayGridMonth')) {
      const newView = isMobile ? 'listMonth' : 'dayGridMonth';
      args.view.calendar.changeView(newView);
    }
  }}
  // Manejar el loading state
  loading={(isLoading) => {
    // Aquí podrías mostrar un indicador de carga si lo deseas
    console.log('Calendar loading state:', isLoading);
  }}
  // Configuración adicional para mejorar el rendimiento
  rerenderDelay={10}
  eventBackgroundColor="transparent"
  eventBorderColor="transparent"
  dayHeaderClassNames="bg-indigo-100 text-indigo-800 uppercase text-sm font-semibold py-2"
  dayCellClassNames="hover:bg-indigo-50 transition-colors duration-200 p-1"
  eventConstraint={{
    startTime: '00:00',
    endTime: '24:00',
  }}
  // Mejorar el formato de la vista de lista
  listDayFormat={{
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  }}
  listDaySideFormat={{
    weekday: 'short'
  }}
  noEventsContent={() => (
    <div className="text-center p-4 text-gray-500">
      No hay eventos programados para este período
    </div>
  )}
/>
      </div>
  
      {/* Lista de posts programados */}
      <div className="mt-8 bg-white rounded-xl shadow-lg p-6">
        <h2 className="text-2xl font-bold mb-4 text-indigo-800">Posts Programados</h2>
        {scheduledPosts.length > 0 ? (
          <List>
            {scheduledPosts.map((post) => (
              <ListItem
                key={post.id}
                className="border-b last:border-b-0 flex justify-between items-center py-4"
              >
                <div className="flex-grow mr-4">
                  <ListItemText
                    primary={
                      <span className="font-semibold text-indigo-700">
                        {post.content.substring(0, 50)}
                        {post.content.length > 50 ? '...' : ''}
                      </span>
                    }
                    secondary={
                      <>
                        <span className="block text-sm text-gray-600">
                          Fecha: {new Date(post.scheduledAt).toLocaleDateString()}
                        </span>
                        <span className="block text-sm text-gray-600">
                          Hora: {new Date(post.scheduledAt).toLocaleTimeString()}
                        </span>
                      </>
                    }
                  />
                </div>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleCancelPost(post.id)}
                  size={isMobile ? "small" : "medium"}
                >
                  {isMobile ? '' : 'Desprogramar'}
                </Button>
              </ListItem>
            ))}
          </List>
        ) : (
          <p className="text-gray-600">No hay posts programados.</p>
        )}
      </div>
  
      {/* Modal de nuevo evento */}
      <RichEditDialog
        open={isNewEventDialogOpen}
        onClose={() => setIsNewEventDialogOpen(false)}
        tempEvent={newEvent}
        onEventChange={handleNewEventChange}
        onSubmit={handleNewEventSubmit}
        onImageUpload={handleImageUpload}
        selectedImage={selectedImage}
        isMobile={isMobile}
      />
  
      {/* Modal de edición de evento */}
      <RichEditDialog
        open={isEditEventDialogOpen}
        onClose={() => setIsEditEventDialogOpen(false)}
        tempEvent={tempEditingEvent}
        onEventChange={handleEditEventChange}
        onSubmit={handleEditEventSubmit}
        onImageUpload={handleImageUpload}
        selectedImage={selectedImage}
        isMobile={isMobile}
      />
    </div>
  );
};

export default Calendar;
