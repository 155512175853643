import React, { useState } from 'react';

const PasswordResetRequestForm = ({ onRequestReset }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onRequestReset(email);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-center mb-6">
          <img src="https://personalmarka.com/wp-content/uploads/2023/09/logo-personal-marka.jpg" alt="PersonalMarka" className="h-16" />
        </div>
        <h2 className="text-3xl font-bold mb-6 text-white text-center">Restablecer Contraseña</h2>
        <p className="text-gray-400 mb-4 text-center">
          Ingresa tu correo electrónico y te enviaremos un código para restablecer tu contraseña.
        </p>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="relative">
            <label htmlFor="email" className="block text-sm font-medium text-gray-300">Correo electrónico:</label>
            <div className="mt-1">
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm text-white placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="tucorreo@ejemplo.com"
              />
            </div>
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Enviar Código
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordResetRequestForm;
