import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Star, Loader2, Calendar } from 'lucide-react';
import { toast } from 'react-toastify';
import axios from 'axios';


// Constants and configuration
const plans = [
  {
    id: 'foco',
    name: 'Plan Foco',
    price: 5.99,
    description: 'Plan inicial para comenzar a construir tu marca',
    features: [
      'Definición de tu perfil personal y tus objetivos',
      'Generación de contenidos mediante inteligencia artificial',
      'Planificación posteos en los mejores días y horarios',
      'Publicaciones en LinkedIn automáticas',
      'Aprendizaje automático con IA generativa'
    ],
    color: 'blue'
  },
  {
    id: 'potencia',
    name: 'Plan Potencia',
    price: 10.99,
    description: 'Para profesionales en crecimiento',
    features: [
      'Definición de tu perfil peronal y tus objetivos',
      'Generación de contenidos mediante inteligencia artificial',
      'Planificación posteos en los mejores días y horarios',
      'Publicaciones en LinkedIn automáticas',
      'Aprendizaje automático con IA generativa',
      'Test de Personalidad',
      'Creación de hasta 4 Post individuales mediante IA'
    ],
    color: 'purple',
    popular: true
  },
  {
    id: 'brillante',
    name: 'Plan Brillante',
    price: 49.99,
    description: 'Máximo impacto y resultados',
    features: [
      'Definición de tu perfil personal y tus objetivos',
      'Generación de contenidos mediante inteligencia artificial',
      'Planificación posteos en los mejores días y horarios',
      'Publicaciones en LinkedIn automáticas',
      'Aprendizaje automático con IA generativa',
      'Test de Personalidad',
      'Creación de hasta 4 Post individuales mediante IA',
      'Asesoría personalizada',
      'Soporte prioritario'
    ],
    color: 'yellow'
  }
];

// Helper functions
const notify = (message, type = 'info') => {
  toast(message, { type });
  console.log(`${type}: ${message}`);
};

const handleSubscribe = async (plan, token, discount = 0) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/subscriptions/create`,
      {
        plan_id: plan.id,
        discount: discount
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.data.init_point && response.data.subscription_id) {
      localStorage.setItem('pending_subscription_id', response.data.subscription_id);
      return response.data;
    } else {
      throw new Error('Error al crear la preferencia de pago');
    }
  } catch (error) {
    console.error('Error al crear la suscripción:', error);
    throw error;
  }
};

// Coupon Verifier Component
const CouponVerifier = ({ onApplyCoupon, appliedDiscount }) => {
  const [couponCode, setCouponCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleVerifyCoupon = async () => {
    if (!couponCode) {
      setError('Ingresa un código de cupón');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const verifyResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/verify-coupon`,
        { code: couponCode },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      );

      if (verifyResponse.data.discount) {
        onApplyCoupon(verifyResponse.data.discount);
        toast.success('Cupón verificado exitosamente');

        if (verifyResponse.data.discount === 100) {
          const applyResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/apply-coupon`,
            { code: couponCode },
            {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            }
          );

          if (applyResponse.data.plan) {
            window.location.reload();
          }
        }
      }
    } catch (error) {
      setError(error.response?.data?.error || 'Error al verificar el cupón');
      toast.error(error.response?.data?.error || 'Error al verificar el cupón');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mb-6 p-4 bg-gray-800 rounded-lg">
      <h3 className="text-lg font-medium text-white mb-4">¿Tienes un cupón?</h3>
      <div className="flex space-x-2">
        <input
          type="text"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
          placeholder="Ingresa tu código"
          className="flex-1 px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
          disabled={loading || appliedDiscount > 0}
        />
        <button
          onClick={handleVerifyCoupon}
          disabled={loading || appliedDiscount > 0}
          className={`px-4 py-2 rounded-lg text-white font-medium transition-colors
            ${loading || appliedDiscount > 0 
              ? 'bg-gray-600 cursor-not-allowed' 
              : 'bg-purple-500 hover:bg-purple-600'}`}
        >
          {loading ? (
            <span className="flex items-center">
              <Loader2 className="animate-spin mr-2" size={16} />
              Verificando...
            </span>
          ) : appliedDiscount > 0 ? (
            'Cupón aplicado'
          ) : (
            'Aplicar'
          )}
        </button>
      </div>
      {error && <p className="mt-2 text-sm text-red-400">{error}</p>}
      {appliedDiscount > 0 && (
        <p className="mt-2 text-sm text-green-400">
          Descuento del {appliedDiscount}% aplicado
        </p>
      )}
    </div>
  );
};

// Plans Component
const PlansComponent = ({ onSelectPlan, hasSubscription, subscriptionPlan }) => {
  const [loading, setLoading] = useState(false);
  const [appliedDiscount, setAppliedDiscount] = useState(0);

  const handlePlanSelection = async (plan) => {
    if (hasSubscription && plan.id === subscriptionPlan) {
      notify('Ya tienes este plan activo', 'info');
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }

      const result = await handleSubscribe(plan, token, appliedDiscount);
      if (result.init_point) {
        window.location.href = result.init_point;
      }
      if (onSelectPlan) {
        onSelectPlan(plan);
      }
    } catch (error) {
      notify(error.message || 'Error al procesar la suscripción', 'error');
    } finally {
      setLoading(false);
    }
  };

  const calculateDiscountedPrice = (price) => {
    if (appliedDiscount) {
      return (price * (100 - appliedDiscount) / 100).toFixed(2);
    }
    return price;
  };

  return (
    <div className="py-12 px-4">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-white mb-4">
            {hasSubscription ? "Tu plan actual" : "Elige tu plan perfecto"}
          </h2>
          {!hasSubscription && (
            <div className="space-y-2">
              <p className="text-lg text-gray-400">
                Compromiso mínimo de 3 meses - Cancela cuando quieras después
              </p>
              <p className="text-sm text-gray-400">
              Renovación automática por el mismo período
              </p>
            </div>
          )}
        </div>

        {!hasSubscription && (
          <CouponVerifier 
            onApplyCoupon={setAppliedDiscount}
            appliedDiscount={appliedDiscount}
          />
        )}
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {plans.map((plan) => (
            <div
              key={plan.id}
              className={`relative rounded-2xl bg-gray-800 p-8 shadow-xl
                ${plan.popular ? 'border-2 border-purple-500 mt-6' : 'border border-gray-700'}
              `}
            >
              {plan.popular && (
                <div className="absolute -top-3 left-1/2 transform -translate-x-1/2">
                  <span className="relative inline-flex px-4 py-1 text-sm font-medium text-white bg-purple-500 rounded-full whitespace-nowrap">
                    Más Popular
                  </span>
                </div>
              )}
              
              <div className="text-center">
                <h3 className="text-2xl font-bold text-white mb-2">{plan.name}</h3>
                <p className="text-gray-400 mb-4">{plan.description}</p>
                <div className="mt-4 flex justify-center items-baseline">
                  {appliedDiscount > 0 ? (
                    <>
                      <span className="text-3xl line-through text-gray-500">${plan.price}</span>
                      <span className="text-5xl font-extrabold text-white ml-2">
                        ${calculateDiscountedPrice(plan.price)}
                      </span>
                    </>
                  ) : (
                    <span className="text-5xl font-extrabold text-white">${plan.price}</span>
                  )}
                  <span className="text-gray-400 ml-2">/mes</span>
                </div>
              </div>

              <ul className="mt-8 space-y-4">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-center">
                    <Star className="w-5 h-5 text-purple-500 mr-3" />
                    <span className="text-gray-300">{feature}</span>
                  </li>
                ))}
              </ul>

              <button
                onClick={() => handlePlanSelection(plan)}
                disabled={loading || (hasSubscription && plan.id === subscriptionPlan)}
                className={`mt-8 w-full py-3 px-6 rounded-lg 
                  ${hasSubscription && plan.id === subscriptionPlan
                    ? 'bg-green-500 cursor-not-allowed'
                    : 'bg-purple-500 hover:bg-purple-600'}
                  text-white font-medium transition-colors duration-200
                  ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {loading ? 'Procesando...' : 
                 hasSubscription && plan.id === subscriptionPlan ? 'Plan Actual' :
                 'Seleccionar Plan'}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// Onboarding Component
const Onboarding = ({ onComplete, hasSubscription, subscriptionPlan }) => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    birthDate: {
      day: '',
      month: '',
      year: ''
    },
    gender: '',
    nationality: '',
    sector: '',
    customSector: '',
    goals: [],
    customGoals: [],
    selectedPlan: subscriptionPlan || ''
  });

  useEffect(() => {
    const savedFormData = localStorage.getItem('onboardingFormData');
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('onboardingFormData', JSON.stringify(formData));
  }, [formData]);

  const steps = [
    {
      index: 1,
      title: "Bienvenido a PersonalMARKA",
      description: "Vamos a configurar tu cuenta para obtener los mejores resultados"
    },
    {
      index: 2,
      title: "Contanos sobre vos",
      description: "Compartí algunos datos básicos para personalizar tu experiencia"
    },
    {
      index: 3,
      title: "Tus Objetivos",
      description: "¿Qué quieres lograr con tu marca personal?"
    }
  ];

  // Current year for age validation (18 years minimum)
  const currentYear = new Date().getFullYear();
  const maxYear = currentYear - 18;
  const years = Array.from({ length: 82 }, (_, i) => maxYear - i);
  
  const months = [
    { value: 1, label: 'Enero' },
    { value: 2, label: 'Febrero' },
    { value: 3, label: 'Marzo' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Mayo' },
    { value: 6, label: 'Junio' },
    { value: 7, label: 'Julio' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Septiembre' },
    { value: 10, label: 'Octubre' },
    { value: 11, label: 'Noviembre' },
    { value: 12, label: 'Diciembre' }
  ];

  const days = Array.from({ length: 31 }, (_, i) => i + 1);

  const sectors = [
    { id: 'tecnologico', label: 'Tecnológico' },
    { id: 'negocios', label: 'Negocios y Emprendimiento' },
    { id: 'consultoria', label: 'Consultoría profesional' },
    { id: 'marketing', label: 'Marketing y Publicidad' },
    { id: 'medios', label: 'Medios de Comunicación y Entretenimiento' },
    { id: 'salud', label: 'Salud y Bienestar' },
    { id: 'educacion', label: 'Educación' },
    { id: 'inmobiliario', label: 'Inmobiliario' },
    { id: 'moda', label: 'Moda' },
    { id: 'otros', label: 'Otros' }
  ];

  const genderOptions = [
    { id: 'femenino', label: 'Femenino' },
    { id: 'masculino', label: 'Masculino' },
    { id: 'otro', label: 'Otro' },
    { id: 'no_especifica', label: 'Prefiero no decirlo' }
  ];

  const countries = [
    'Argentina', 'Bolivia', 'Brasil', 'Chile', 'Colombia', 'Costa Rica',
    'Cuba', 'Ecuador', 'El Salvador', 'Guatemala', 'Honduras', 'México',
    'Nicaragua', 'Panamá', 'Paraguay', 'Perú', 'República Dominicana',
    'Uruguay', 'Venezuela', 'Otro'
  ];

  const handleAddCustomGoal = (e) => {
    if (e.key === 'Enter' && e.target.value.trim()) {
      setFormData({
        ...formData,
        customGoals: [...formData.customGoals, e.target.value.trim()]
      });
      e.target.value = '';
    }
  };

  const handleRemoveCustomGoal = (goalToRemove) => {
    setFormData({
      ...formData,
      customGoals: formData.customGoals.filter(goal => goal !== goalToRemove)
    });
  };

  const validateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate.year, birthDate.month - 1, birthDate.day);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    
    return age >= 18;
  };

  const handleNext = () => {
    if (step === 2) {
      const { day, month, year } = formData.birthDate;
      
      // Validate age (18+)
      if (!validateAge(formData.birthDate)) {
        notify('Debes ser mayor de 18 años para registrarte', 'error');
        return;
      }

      if (!day || !month || !year || !formData.gender || !formData.nationality || 
          (!formData.sector || (formData.sector === 'otros' && !formData.customSector))) {
        notify('Por favor, completa todos los campos requeridos', 'error');
        return;
      }
    }

    if (step === 3 && formData.goals.length === 0 && formData.customGoals.length === 0) {
      notify('Por favor, selecciona o ingresa al menos un objetivo', 'error');
      return;
    }

    if (step < steps.length) {
      setStep(step + 1);
    } else {
      const formattedData = {
        ...formData,
        birthDate: `${formData.birthDate.year}-${String(formData.birthDate.month).padStart(2, '0')}-${String(formData.birthDate.day).padStart(2, '0')}`
      };
      localStorage.removeItem('onboardingFormData');
      onComplete(formattedData);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-gray-800 rounded-2xl p-8 w-full max-w-4xl overflow-y-auto max-h-[90vh]">
        <div className="mb-8">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-2xl font-bold text-white">
              {steps.find(s => s.index === step)?.title}
            </h2>
            <span className="text-gray-400">
              Paso {step} de {steps.length}
            </span>
          </div>
          <p className="text-gray-400">{steps.find(s => s.index === step)?.description}</p>
        </div>

        <div className="space-y-8">
          {step === 1 && (
            <div className="text-center">
              <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Star className="w-20 h-20 text-yellow-500 mx-auto mb-6" />
                <h3 className="text-xl text-white mb-4">
                  ¡Estamos emocionados de tenerte aquí!
                </h3>
                <p className="text-gray-400 mb-8">
                  En los siguientes pasos configuraremos tu cuenta para 
                  maximizar tu potencial en la construcción de tu marca personal.
                </p>
              </motion.div>
            </div>
          )}

          {step === 2 && (
            <div className="space-y-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Fecha de nacimiento <span className="text-red-500">*</span>
                </label>
                <div className="grid grid-cols-3 gap-2">
                  <select
                    value={formData.birthDate.day}
                    onChange={(e) => setFormData({
                      ...formData,
                      birthDate: { ...formData.birthDate, day: e.target.value }
                    })}
                    className="bg-gray-700 text-white rounded-lg p-3"
                  >
                    <option value="">Día</option>
                    {days.map(day => (
                      <option key={day} value={day}>{day}</option>
                    ))}
                  </select>
                  <select
                    value={formData.birthDate.month}
                    onChange={(e) => setFormData({
                      ...formData,
                      birthDate: { ...formData.birthDate, month: e.target.value }
                    })}
                    className="bg-gray-700 text-white rounded-lg p-3"
                  >
                    <option value="">Mes</option>
                    {months.map(month => (
                      <option key={month.value} value={month.value}>{month.label}</option>
                    ))}
                  </select>
                  <select
                    value={formData.birthDate.year}
                    onChange={(e) => setFormData({
                      ...formData,
                      birthDate: { ...formData.birthDate, year: e.target.value }
                    })}
                    className="bg-gray-700 text-white rounded-lg p-3"
                  >
                    <option value="">Año</option>
                    {years.map(year => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Género <span className="text-red-500">*</span>
                </label>
                <select
                  value={formData.gender}
                  onChange={(e) => setFormData({...formData, gender: e.target.value})}
                  className="w-full bg-gray-700 text-white rounded-lg p-3"
                >
                  <option value="">Seleccionar...</option>
                  {genderOptions.map(option => (
                    <option key={option.id} value={option.id}>{option.label}</option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Nacionalidad <span className="text-red-500">*</span>
                </label>
                <select
                  value={formData.nationality}
                  onChange={(e) => setFormData({...formData, nationality: e.target.value})}
                  className="w-full bg-gray-700 text-white rounded-lg p-3"
                >
                  <option value="">Seleccionar...</option>
                  {countries.map(country => (
                    <option key={country} value={country}>{country}</option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Sector de trabajo <span className="text-red-500">*</span>
                </label>
                <select
                  value={formData.sector}
                  onChange={(e) => setFormData({...formData, sector: e.target.value})}
                  className="w-full bg-gray-700 text-white rounded-lg p-3 mb-2"
                >
                  <option value="">Seleccionar...</option>
                  {sectors.map(option => (
                    <option key={option.id} value={option.id}>{option.label}</option>
                  ))}
                </select>
                {formData.sector === 'otros' && (
                  <input
                    type="text"
                    placeholder="Especifica tu sector"
                    value={formData.customSector}
                    onChange={(e) => setFormData({...formData, customSector: e.target.value})}
                    className="w-full bg-gray-700 text-white rounded-lg p-3 mt-2"
                  />
                )}
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Selecciona tus objetivos principales
                </label>
                <div className="space-y-3 mb-4">
                  {[
                    'Aumentar visibilidad profesional',
                    'Conseguir mejores oportunidades laborales',
                    'Establecerme como referente en mi industria',
                    'Atraer nuevos clientes',
                    'Crear una comunidad',
                    'Compartir conocimiento'
                  ].map((goal) => (
                    <label key={goal} className="flex items-center space-x-3">
                      <input
                        type="checkbox"
                        checked={formData.goals.includes(goal)}
                        onChange={(e) => {
                          const newGoals = e.target.checked
                            ? [...formData.goals, goal]
                            : formData.goals.filter(g => g !== goal);
                          setFormData({...formData, goals: newGoals});
                        }}
                        className="form-checkbox h-5 w-5 text-blue-500"
                      />
                      <span className="text-gray-300">{goal}</span>
                    </label>
                  ))}
                </div>

                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Agrega tus propios objetivos
                  </label>
                  <input
                    type="text"
                    placeholder="Presiona Enter para agregar"
                    onKeyPress={handleAddCustomGoal}
                    className="w-full bg-gray-700 text-white rounded-lg p-3 mb-2"
                  />
                  {formData.customGoals.length > 0 && (
                    <div className="space-y-2">
                      {formData.customGoals.map((goal, index) => (
                        <div key={index} className="flex items-center justify-between bg-gray-700 p-2 rounded-lg">
                          <span className="text-gray-300">{goal}</span>
                          <button
                            onClick={() => handleRemoveCustomGoal(goal)}
                            className="text-red-400 hover:text-red-500"
                          >
                            ×
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="mt-8 flex justify-between">
            {step > 1 && (
              <button
                onClick={() => setStep(step - 1)}
                disabled={loading}
                className="px-6 py-2 bg-gray-700 text-white rounded-lg"
              >
                Anterior
              </button>
            )}
            <button
              onClick={handleNext}
              disabled={loading}
              className={`px-6 py-2 bg-purple-500 text-white rounded-lg ml-auto 
                ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-purple-600'}`}
            >
              {loading ? 'Procesando...' : (step === steps.length ? 'Completar' : 'Siguiente')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Completion Modal Component
const CompletionModal = ({ isOpen, onClose, hasSubscription, subscriptionPlan, onSubscriptionComplete }) => {
  const [showPlans, setShowPlans] = useState(false);

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 
        ${isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'} transition-all duration-300`}
    >
      <div className={`bg-gray-800 rounded-lg p-8 ${showPlans ? 'max-w-6xl' : 'max-w-md'} w-full mx-4 overflow-y-auto max-h-[90vh]`}>
        {!showPlans ? (
          <>
            <h2 className="text-2xl font-bold text-white mb-4">
              Completa tu suscripción
            </h2>
            <p className="text-gray-300 mb-6">
              Para acceder a todas las funcionalidades de PersonalMARKA, 
              necesitas elegir un plan de suscripción.
            </p>
            {!hasSubscription && (
              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => setShowPlans(true)}
                  className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors"
                >
                  Ver planes
                </button>
              </div>
            )}
            {hasSubscription && (
              <div className="flex justify-end">
                <button
                  onClick={onClose}
                  className="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded-lg transition-colors"
                >
                  Cerrar
                </button>
              </div>
            )}
          </>
        ) : (
          <div>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-white">
                {hasSubscription ? "Tu plan actual" : "Selecciona tu plan"}
              </h2>
              <button
                onClick={() => setShowPlans(false)}
                className="text-gray-400 hover:text-white transition-colors"
              >
                ← Volver
              </button>
            </div>
            
            <PlansComponent 
              hasSubscription={hasSubscription}
              subscriptionPlan={subscriptionPlan}
              onSelectPlan={(plan) => {
                if (onSubscriptionComplete) {
                  onSubscriptionComplete();
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

// Subscription Hook
const useSubscription = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const checkSubscriptionStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/subscriptions/status`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error checking subscription status:', error);
      throw error;
    }
  };

  const handleSubscriptionCallback = async (status) => {
    if (status === 'success') {
      const subscriptionId = localStorage.getItem('pending_subscription_id');
      if (subscriptionId) {
        try {
          const token = localStorage.getItem('token');
          await axios.post(
            `${process.env.REACT_APP_API_URL}/api/subscriptions/confirm`,
            { subscription_id: subscriptionId },
            {
              headers: { Authorization: `Bearer ${token}` }
            }
          );
          localStorage.removeItem('pending_subscription_id');
        } catch (error) {
          console.error('Error confirming subscription:', error);
          throw error;
        }
      }
    }
  };

  return {
    loading,
    error,
    checkSubscriptionStatus,
    handleSubscriptionCallback
  };
};

// Main Manager Component
const Manager = ({ children }) => {
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const { checkSubscriptionStatus, handleSubscriptionCallback } = useSubscription();

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          notify('No se encontró el token de autenticación', 'error');
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/check-onboarding`,
          {
            headers: { 
              'Authorization': `Bearer ${token}` 
            }
          }
        );

        console.log('Respuesta de check-onboarding:', response.data);

        const { onboardingCompleted, hasSubscription, subscriptionPlan } = response.data;

        setIsOnboardingCompleted(onboardingCompleted);
        setHasSubscription(hasSubscription);
        setSubscriptionPlan(subscriptionPlan);

        if (!onboardingCompleted) {
          setShowCompletionModal(true);
        } else {
          setShowCompletionModal(false);
        }
      } catch (error) {
        notify('Error al verificar estado de onboarding', 'error');
      }
    };

    checkStatus();
  }, []);

  const handleOnboardingComplete = async (data) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/complete-onboarding`, 
        data,
        {
          headers: { 
            'Authorization': `Bearer ${token}` 
          }
        }
      );

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/check-onboarding`,
        {
          headers: { 
            'Authorization': `Bearer ${token}` 
          }
        }
      );

      const { onboardingCompleted, hasSubscription, subscriptionPlan } = response.data;

      setIsOnboardingCompleted(onboardingCompleted);
      setHasSubscription(hasSubscription);
      setSubscriptionPlan(subscriptionPlan);

      if (!onboardingCompleted || !hasSubscription) {
        setShowCompletionModal(true);
      } else {
        setShowCompletionModal(false);
      }
    } catch (error) {
      notify('Error completando onboarding', 'error');
    }
  };

  return (
    <>
      {children}

      {showCompletionModal && (
        <Onboarding
          onComplete={handleOnboardingComplete}
          hasSubscription={hasSubscription}
          subscriptionPlan={subscriptionPlan}
        />
      )}
    </>
  );
};

// Exports
export {
  PlansComponent as SubscriptionPlans,
  CompletionModal as SubscriptionCompletionModal,
  Onboarding as OnboardingFlow,
  Manager as default
};