import React, { useState, useEffect } from 'react';
import { FaBullhorn, FaLightbulb } from 'react-icons/fa';

const MarketingPlanGenerator = ({ generatingPlan, initialProgress = 0 }) => {
  const [progress, setProgress] = useState(initialProgress);
  const [curiosityIndex, setCuriosityIndex] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(null);

  const marketingCuriosities = [
    "El color rojo puede aumentar el apetito.",
    "El 90% de las decisiones de compra son subconscientes.",
    "Las emociones influyen más que la información en la toma de decisiones.",
    "El marketing de contenidos genera tres veces más leads que el outbound marketing.",
    "El 93% de las interacciones en internet comienzan con un motor de búsqueda.",
    "Los videos aumentan la tasa de conversión en un 80%.",
    "El correo electrónico tiene un ROI del 4400%.",
    "El 72% de los consumidores prefieren conocer un producto a través de un video.",
    "El 47% de los consumidores ven entre 3 y 5 contenidos antes de interactuar con un vendedor.",
    "El 80% de los usuarios dejan de leer un contenido si no se muestra bien en su dispositivo.",
    "El storytelling aumenta la comprensión y retención de información.",
    "Las imágenes son procesadas 60,000 veces más rápido que el texto.",
    "El neuromarketing utiliza neurociencia para entender el comportamiento del consumidor.",
    "Los colores pueden aumentar el reconocimiento de marca en un 80%.",
    "El 92% de los consumidores confían en las recomendaciones de personas que conocen.",
    "Las campañas personalizadas pueden incrementar las ventas en un 20%.",
    "El 74% de los consumidores se frustran con contenido que no es relevante para ellos.",
    "El remarketing puede aumentar las tasas de conversión en un 150%.",
    "Los programas de fidelización pueden aumentar la retención de clientes en un 5%.",
    "El boca a boca es responsable del 50% de las decisiones de compra.",
    "El 70% de los consumidores prefieren aprender sobre una empresa a través de artículos.",
    "El 88% de los profesionales de marketing utilizan las redes sociales para distribuir contenido.",
    "El 55% de los usuarios de internet investigan en línea antes de realizar una compra.",
    "El 81% de los consumidores buscan en línea antes de realizar una compra importante.",
    "Las publicaciones en blogs generan el 67% de los leads generados por marketing de contenidos.",
    "El 60% de los profesionales de marketing dicen que aumentar la generación de leads es su principal objetivo.",
    "El contenido visual es un 40 veces más compartible en redes sociales que el contenido escrito.",
    "El 80% de los usuarios de Facebook revisan la página al menos una vez al día.",
    "El 78% de las empresas con blogs obtienen clientes potenciales de su blog.",
    "El 94% de las compañías que utilizan blogs ven un aumento en el ROI de sus estrategias de marketing.",
    "El 50% de las empresas utilizan video marketing como parte de su estrategia.",
    "El 70% de los usuarios de Instagram han visitado un perfil de empresa.",
    "El 72% de los especialistas en marketing afirman que el video genera más conversiones que cualquier otro formato.",
    "El 85% de los negocios que usan videos en sus estrategias de marketing ven un aumento en las ventas.",
    "El 66% de los consumidores prefieren interactuar con contenido visual sobre texto.",
    "El 93% de las campañas de email marketing alcanzan su objetivo de comunicación.",
    "El 47% de los usuarios de Facebook han comprado un producto después de verlo en un anuncio.",
    "El 73% de los especialistas en marketing afirman que el contenido de video tiene un buen retorno de inversión.",
    "El 80% de los consumidores recuerdan un video que han visto en las redes sociales.",
    "El 87% de las empresas utilizan el email marketing como una parte importante de su estrategia de marketing.",
    "El 70% de los consumidores prefieren recibir información sobre productos a través de artículos o blogs.",
    "El 74% de los especialistas en marketing están de acuerdo en que las redes sociales son cruciales para su negocio.",
    "El 79% de los especialistas en marketing de contenido creen que su estrategia de marketing de contenido es efectiva.",
    "El 60% de los especialistas en marketing utilizan marketing de influencers para aumentar la visibilidad de su marca.",
    "El 80% de los consumidores han realizado una compra como resultado de recomendaciones en redes sociales.",
    "El 82% de los especialistas en marketing dicen que las redes sociales han incrementado la visibilidad de su negocio.",
    "El 75% de los usuarios de redes sociales toman acciones basadas en las recomendaciones que ven en esas plataformas.",
    "El 77% de los profesionales de marketing consideran que el contenido generado por el usuario es efectivo para sus estrategias.",
    "El 91% de los usuarios de Instagram siguen al menos a una empresa en la plataforma.",
    "El 73% de los especialistas en marketing dicen que el contenido visual mejora el reconocimiento de marca."
  ];

  useEffect(() => {
    if (generatingPlan && progress < 100) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => {
          const increment = Math.random() * 0.5 + 0.1; // Incremento aleatorio entre 0.1 y 0.6
          const newProgress = prevProgress + increment;
          return newProgress > 100 ? 100 : newProgress;
        });
      }, 300); // Intervalo de 300ms
      return () => clearInterval(timer);
    }
  }, [generatingPlan, progress]);

  useEffect(() => {
    if (generatingPlan) {
      const curiosityTimer = setInterval(() => {
        let newIndex;
        do {
          newIndex = Math.floor(Math.random() * marketingCuriosities.length);
        } while (newIndex === curiosityIndex && marketingCuriosities.length > 1);
        setPreviousIndex(curiosityIndex);
        setCuriosityIndex(newIndex);
      }, 4000); // Cambia la curiosidad cada 4 segundos
      return () => clearInterval(curiosityTimer);
    }
  }, [generatingPlan, curiosityIndex, marketingCuriosities.length]);

  if (!generatingPlan) return null;

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 text-white relative overflow-hidden">
      {/* Fondo animado */}
      <div className="absolute inset-0 bg-gradient-to-br from-gray-800 via-gray-900 to-black animate-gradient"></div>

      {/* Sección de Generación del Plan de Marketing */}
      <div className="z-10 w-11/12 md:w-1/3 p-8 bg-gray-800 rounded-xl shadow-2xl border border-gray-700 mb-8">
        <div className="flex flex-col items-center">
          <FaBullhorn className="text-purple-400 text-4xl mb-4 animate-pulse" />
          <h2 className="text-2xl md:text-3xl font-extrabold mb-4 text-center">Generando Plan de Marketing</h2>
          <div className="w-full mb-6">
            <div className="flex justify-between mb-2">
              <span className="text-lg font-medium text-purple-300">{Math.floor(progress)}% Completado</span>
              <span className="text-sm font-light text-gray-400">Progreso</span>
            </div>
            <div className="w-full bg-gray-700 rounded-full h-3">
              <div 
                className="bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 h-3 rounded-full transition-all duration-500 ease-out"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>
          <p className="text-sm text-gray-300 italic text-center">
            Analizando datos y optimizando estrategias...
          </p>
        </div>
      </div>

      {/* Sección de Curiosidades de Marketing */}
      <div className="z-10 w-11/12 md:w-2/3 p-6 bg-gray-800 rounded-xl shadow-2xl border border-gray-700 flex flex-col items-center">
        <div className="flex items-center mb-4">
          <FaLightbulb className="text-yellow-500 text-2xl mr-2 animate-bounce" />
          <h3 className="text-xl md:text-2xl font-semibold text-center">Curiosidades</h3>
          <FaLightbulb className="text-yellow-500 text-2xl mr-2 animate-bounce" />
        </div>
        <div className="w-full h-24 flex items-center justify-center">
          <p className="text-lg text-gray-200 italic text-center px-4 transition-opacity duration-700">
            {marketingCuriosities[curiosityIndex]}
          </p>
        </div>
        {/* Indicadores de carrusel */}
        <div className="flex flex-wrap justify-center space-x-1 mt-4">
          {/* Mostrar solo 10 indicadores para evitar sobrecarga visual */}
          {marketingCuriosities.slice(0, 10).map((_, index) => (
            <span
              key={index}
              className={`w-2 h-2 rounded-full ${
                index === curiosityIndex % 10 ? 'bg-yellow-500' : 'bg-gray-500'
              }`}
            ></span>
          ))}
        </div>
      </div>

      {/* Animaciones de fondo */}
      <style jsx>{`
        @keyframes gradientAnimation {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
        .animate-gradient {
          background-size: 400% 400%;
          animation: gradientAnimation 15s ease infinite;
        }
      `}</style>
    </div>
  );
};

export default MarketingPlanGenerator;
