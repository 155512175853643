import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDownIcon, PencilSquareIcon } from '@heroicons/react/24/outline';

const ResponseHistory = ({ responses, savedPlans, onEditResponses }) => {
  const [expandedSections, setExpandedSections] = useState({});

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  // Función para formatear el nombre de la sección
  const formatSectionName = (section) => {
    const sectionMappings = {
      'Entrevista': 'Información Personal',
      'Test de Personalidad': 'Test de Personalidad',
      'Intereses': 'Intereses y Preferencias',
      'Posteos Anteriores': 'Historial de Publicaciones',
      'Preferencias de Marketing': 'Preferencias de Marketing'
    };
    return sectionMappings[section] || section;
  };

  return (
    <div className="max-w-4xl mx-auto pt-6 pb-24 px-4">
      {/* Header */}
      <div className="mb-8">
        <h2 className="text-3xl font-bold text-white mb-4">Historial de Respuestas</h2>
        <p className="text-gray-400">
          Aquí puedes revisar todas tus respuestas anteriores y editarlas si es necesario.
        </p>
      </div>

      {/* Sections */}
      <div className="space-y-6">
        {Object.entries(responses).map(([section, questions]) => (
          <motion.div
            key={section}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-gray-700/50 overflow-hidden"
          >
            {/* Section Header */}
            <button
              onClick={() => toggleSection(section)}
              className="w-full flex items-center justify-between p-6 hover:bg-gray-700/30 transition-colors duration-200"
            >
              <div className="flex items-center gap-4">
                <div className="w-2 h-2 rounded-full bg-blue-500"></div>
                <h3 className="text-xl font-semibold text-white">
                  {formatSectionName(section)}
                </h3>
              </div>
              <motion.div
                animate={{ rotate: expandedSections[section] ? 180 : 0 }}
                transition={{ duration: 0.2 }}
              >
                <ChevronDownIcon className="w-6 h-6 text-gray-400" />
              </motion.div>
            </button>

            {/* Section Content */}
            <AnimatePresence>
              {expandedSections[section] && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="px-6 pb-6">
                    <div className="space-y-4 border-t border-gray-700/50 pt-4">
                      {Object.entries(questions).map(([question, answer]) => (
                        <div
                          key={question}
                          className="bg-gray-900/30 rounded-lg p-4 hover:bg-gray-900/50 transition-colors duration-200"
                        >
                          <p className="text-gray-300 font-medium mb-2">{question}</p>
                          <p className="text-gray-400">{answer.value || 'Sin respuesta'}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ))}
      </div>

      {/* Action Buttons */}
      <div className="fixed bottom-0 right-0 left-0 lg:left-72 z-30">
        <div className="bg-gradient-to-t from-gray-900 via-gray-900/95 to-transparent backdrop-blur-sm px-4 py-4">
          <div className="max-w-7xl mx-auto flex justify-center">
            <motion.button
              onClick={onEditResponses}
              className="flex items-center gap-2 px-6 py-3 bg-blue-600/90 hover:bg-blue-500/90 
                text-white font-medium rounded-lg transition-all duration-200 
                border border-blue-500/20 shadow-lg hover:shadow-blue-500/20"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <PencilSquareIcon className="w-5 h-5" />
              Editar Respuestas
            </motion.button>
          </div>
        </div>
      </div>

      {/* Empty State */}
      {Object.keys(responses).length === 0 && (
        <div className="text-center py-12">
          <div className="bg-gray-800/50 rounded-xl p-8 max-w-lg mx-auto">
            <h3 className="text-xl font-semibold text-white mb-4">
              No hay respuestas guardadas
            </h3>
            <p className="text-gray-400 mb-6">
              Parece que aún no has completado ninguna sección. Comienza el cuestionario para ver tus respuestas aquí.
            </p>
            <motion.button
              onClick={onEditResponses}
              className="px-6 py-3 bg-blue-600 hover:bg-blue-500 text-white 
                font-medium rounded-lg transition-all duration-200"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Comenzar Cuestionario
            </motion.button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResponseHistory;