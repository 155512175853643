import React, { useState } from 'react';

const PasswordResetForm = ({ onResetPassword, email }) => {
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert("Las contraseñas no coinciden");
      return;
    }
    onResetPassword(email, code, newPassword);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-center mb-6">
          <img src="https://personalmarka.com/wp-content/uploads/2023/09/logo-personal-marka.jpg" alt="PersonalMarka" className="h-16" />
        </div>
        <h2 className="text-3xl font-bold mb-6 text-white text-center">Restablecer Contraseña</h2>
        <p className="text-gray-400 mb-4 text-center">
          Ingresa el código que te hemos enviado a <span className="text-blue-500">{email}</span> y crea una nueva contraseña.
        </p>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="relative">
            <label htmlFor="code" className="block text-sm font-medium text-gray-300">Código de Verificación:</label>
            <div className="mt-1">
              <input
                type="text"
                id="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
                className="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm text-white placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="123456"
              />
            </div>
          </div>
          <div className="relative">
            <label htmlFor="new-password" className="block text-sm font-medium text-gray-300">Nueva Contraseña:</label>
            <div className="mt-1">
              <input
                type="password"
                id="new-password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                className="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm text-white placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="********"
              />
            </div>
          </div>
          <div className="relative">
            <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-300">Confirmar Contraseña:</label>
            <div className="mt-1">
              <input
                type="password"
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md shadow-sm text-white placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="********"
              />
            </div>
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Restablecer Contraseña
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordResetForm;
